import { User } from '../../../../types';
import { defineMessage, useIntl } from 'react-intl';
import React, { useContext, useEffect, useState } from 'react';
import {
	AppContext,
	CustomerContext,
	GroupsContext,
} from '../../../../context';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import { Checkbox, Modal, MODAL_MEDIUM } from '@planview/pv-uikit';
import { CheckboxGroup, Form } from '@planview/pv-form';
import { getGroups } from '../../systemGroups/systemGroupsUtils';
import { Action } from '../../../../types/menu';

const setGroupsMessage = defineMessage({
	id: 'customercare.users.setGroups',
	description: 'Action for customer care to manage groups assigned to users',
	defaultMessage: 'Manage system groups',
});

type SetGroupsDialogProps = {
	onConfirm: () => void;
	onCancel: () => void;
	users: User[];
};

/**
 * Dialog to set groups on a user
 */
const GroupsDialog = (props: SetGroupsDialogProps) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const groupsContext = useContext(GroupsContext);
	const { onConfirm, onCancel, users } = props;
	const customerContext = useContext(CustomerContext);
	const [isSaving, setIsSaving] = useState(false);
	const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
	const [unselectedGroupIds, setUnselectedGroupIds] = useState<string[]>([]);
	const [indeterminateGroupIds, setIndeterminateGroupIds] = useState<
		string[]
	>([]);

	const customerId = customerContext.customer.id;
	useEffect(() => {
		const userCount = users.length;
		const groupCounts = new Map<string, number>();
		users.forEach(({ systemGroupIds }) => {
			systemGroupIds?.forEach((groupId) => {
				const currentCount = groupCounts.get(groupId) || 0;
				groupCounts.set(groupId, currentCount + 1);
			});
		});
		const selectedGroupIds = [] as string[];
		const indeterminateGroupIds = [] as string[];
		groupCounts.forEach((count, groupId) => {
			if (count === userCount) {
				selectedGroupIds.push(groupId);
			} else if (count > 0) {
				indeterminateGroupIds.push(groupId);
			}
		});
		setSelectedGroupIds(selectedGroupIds);
		setIndeterminateGroupIds(indeterminateGroupIds);
	}, [users]);

	const handleSubmit = async () => {
		setIsSaving(true);
		const userIds = users.map((user) => user.id);
		const dataObj = {
			customerId,
			userIds,
			groupIdsToAdd: selectedGroupIds,
			groupIdsToRemove: unselectedGroupIds,
		};
		const { success } = await requestWithErrorHandling({
			method: 'post',
			url: `/io/v1/admin/group/system/manage`,
			appContext,
			intl,
			dataObj,
		});

		if (success) {
			onConfirm();
		} else {
			setIsSaving(false);
		}
	};

	// Add or remove the id to the selectedGroupIds based on new "selected" value
	const handleCheckChange = (id: string, selected: boolean) => {
		if (selected) {
			setSelectedGroupIds([...selectedGroupIds, id]);
			setUnselectedGroupIds(
				unselectedGroupIds.filter((groupId) => groupId !== id),
			);
		} else {
			setSelectedGroupIds(
				selectedGroupIds.filter((groupId) => groupId !== id),
			);
			setUnselectedGroupIds([...unselectedGroupIds, id]);
		}
		setIndeterminateGroupIds(
			indeterminateGroupIds.filter((groupId) => groupId !== id),
		);
	};

	return (
		<Modal
			size={MODAL_MEDIUM}
			onConfirm={() => {
				setIsSaving(true);
				void handleSubmit();
			}}
			onCancel={onCancel}
			cancelText="Cancel"
			confirmText="Set groups"
			headerText="Set groups"
			disableConfirm={isSaving}
		>
			<Form label="Set groups">
				<CheckboxGroup
					error=""
					id="systemGroups"
					label="Select groups to assign to the users"
				>
					{getGroups(appContext, customerContext, groupsContext).map(
						(group) => (
							<Checkbox
								label={group.title}
								id={group.id}
								key={group.id}
								selected={selectedGroupIds.includes(group.id)}
								indeterminate={indeterminateGroupIds.includes(
									group.id,
								)}
								onChange={(value) =>
									handleCheckChange(group.id, value)
								}
							/>
						),
					)}
				</CheckboxGroup>
			</Form>
		</Modal>
	);
};

const useSetGroupsAction = ({ refresh }: { refresh: () => void }) => {
	const [showDialog, setShowDialog] = useState(false);
	const [users, setUsers] = useState<User[]>([]);

	const setGroupsModal = showDialog ? (
		<GroupsDialog
			key="setPasswordModal"
			users={users}
			onConfirm={() => {
				setShowDialog(false);
				refresh();
			}}
			onCancel={() => setShowDialog(false)}
		/>
	) : null;

	const setGroupsAction: Action<User> = {
		icon: null,
		message: setGroupsMessage,
		isEnabled: (users: User[]) => users.length > 0,
		activateFn: (users: User[]) => {
			setUsers(users);
			setShowDialog(true);
		},
	};

	return {
		setGroupsModal,
		setGroupsAction,
	};
};

export default useSetGroupsAction;
