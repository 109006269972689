import { defineMessages } from 'react-intl';

const messages = defineMessages({
	header: {
		id: 'admin.settings.organizationProfile.avatarRemoveModal.header',
		defaultMessage: 'Remove organization avatar',
		description: 'Header for the remove avatar modal',
	},
	body: {
		id: 'admin.settings.organizationProfile.avatarRemoveModal.body',
		defaultMessage:
			'Are you sure you want to remove the custom organization avatar? This means you won’t be able to see your organization’s name, avatar, or domain within the product switcher.',
		description: 'Body text for the remove avatar modal',
	},
	confirmButton: {
		id: 'admin.settings.organizationProfile.avatarRemoveModal.confirmButton',
		defaultMessage: 'Remove',
		description: 'Button label for confirming the removal of the avatar',
	},
	cancelButton: {
		id: 'admin.settings.organizationProfile.avatarRemoveModal.cancelButton',
		defaultMessage: 'Cancel',
		description: 'Button label for cancelling the removal of the avatar',
	},
});

export default messages;
