import React, { lazy, ReactNode, Suspense, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import {
	BrowserRouter,
	Navigate,
	Outlet,
	Route,
	Routes,
	useLocation,
	useResolvedPath,
} from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';
import { SIZE_XLARGE, Spinner } from '@planview/pv-uikit';
import { align, theme, sizePx } from '@planview/pv-utilities';
import LoginPage from './containers/login/LoginPage';
import Toast from './components/common/toast/Toast';
import RefreshAppModal from './components/common/refresh/RefreshAppModal';
import BaseRoute from './components/common/route/BaseRoute';
import ProtectedRoute from './components/common/route/ProtectedRoute';
import PVAdminProtectedRoute from './components/common/route/PVAdminProtectedRoute';
import GlobalState from './context/GlobalState';
import NotificationsPage from './containers/notifications/NotificationsPage';
import { LocationState, UrlParams } from './types/hooks/navigate';
import { Messages } from './messages/intl';
import OrganizationInfoPage from './containers/pvadmin/organizationInfo/OrganizationInfoPage';
import OrganizationsPage from './containers/pvadmin/organization/OrganizationsPage';
import OverviewPage from './containers/common/overview/OverviewPage';
import UsersTabsPage from './containers/admin/users/UsersTabsPage';
import ProductsPage from './containers/common/products/ProductsPage';
import ProductDetailsPage from './containers/admin/productDetails/ProductDetailsPage';
import SettingsPage from './containers/admin/settings/SettingsPage';
import ServiceSettingsContainer from './containers/admin/settings/ServiceSettingsContainer';
import { logError } from './hooks/request/request';
import { AppContext, UserContext } from './context';
import PVAdminPage from './containers/pvadmin/PVAdminPage';

const PageOutlet = lazy(() => import('./containers/common/PageOutlet'));

const AppDiv = styled.div`
	${align.centerV}
	background-color: ${theme.gray50};
	font-size: ${sizePx.xsmall};
	flex-direction: column;
	height: 100vh;
`;

const filterSearch = (search: string) => {
	// error, info, success are handled in Route, we don't need to pass it along further
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { error, info, success, ...rest } = qs.parse(search, {
		ignoreQueryPrefix: true,
	});
	return qs.stringify(rest, { addQueryPrefix: true });
};

export const processUrlParams = (params: UrlParams): UrlParams => {
	const { state, search } = params;
	const { redirect } = state;

	return {
		state: {
			redirect,
		},
		search: filterSearch(search),
	};
};

type WrapperProps = {
	locale: string;
	messages: Messages;
	children: ReactNode;
	theme: ThemeMode;
};

const Wrapper = (props: WrapperProps) => {
	const { locale, messages, children, theme } = props;
	return (
		<IntlProvider key={locale} locale={locale} messages={messages}>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore */}
			<GlobalState theme={theme}>
				<AppDiv>
					<Suspense fallback={<Spinner size={SIZE_XLARGE} />}>
						<Toast />
						<RefreshAppModal />
						{children}
					</Suspense>
				</AppDiv>
			</GlobalState>
		</IntlProvider>
	);
};

export const InvalidNavigate = () => {
	const path = useResolvedPath('').pathname;
	void logError(`Invalid path: ${path}`);
	return <Navigate to="/" replace />;
};

export const SecurityLevelNavigate = () => {
	const path = useResolvedPath('').pathname;
	const { overviewApplications } = useContext(AppContext);
	const { isSuperAdmin, isAdmin, isCustomerCare, hasCustomerCareAccess } =
		useContext(UserContext);

	if (isSuperAdmin) {
		return <Navigate to={`${path}/overview`} replace />;
	}

	if (hasCustomerCareAccess()) {
		return <Navigate to="/pvadmin" replace />;
	}

	if (isAdmin && !isCustomerCare && overviewApplications.length === 0) {
		return <Navigate to={`${path}/products`} replace />;
	}

	return <Navigate to={`${path}/overview`} replace />;
};

export const Redirect = () => {
	const location = useLocation();
	const state = (location.state as LocationState) || {};
	const { search } = location;
	const urlParams: UrlParams = { search, state };
	const processedUrlParams = processUrlParams(urlParams);
	const { state: processedState, search: processedSearch } =
		processedUrlParams;
	const { redirect = false } = processedState;

	return !redirect ? (
		<Navigate to={{ pathname: '/home', search: processedSearch }} />
	) : (
		<LoginPage type="login" />
	);
};

type AppProps = {
	locale: string;
	messages: Messages;
};

export type ThemeMode = 'auto' | 'dark' | 'light';

const USER_THEME_PREF_KEY = 'user-theme-preference';
export const BASE_PATH = '/';

const App = (props: AppProps) => {
	const { locale, messages } = props;
	const [themeMode, _setThemeMode] = React.useState<ThemeMode>(() => {
		const data = window.localStorage.getItem(USER_THEME_PREF_KEY);
		if (data) {
			return data as ThemeMode;
		}
		return 'light';
	});

	const setThemeMode = React.useCallback((themeMode: ThemeMode) => {
		_setThemeMode(themeMode);
		window.localStorage.setItem(USER_THEME_PREF_KEY, themeMode);
	}, []);

	return (
		<Wrapper locale={locale} messages={messages} theme={themeMode}>
			<BrowserRouter>
				<Routes>
					<Route
						path={BASE_PATH}
						element={<BaseRoute element={<Redirect />} />}
					/>
					<Route
						path="signout"
						element={
							<BaseRoute element={<LoginPage type="signout" />} />
						}
					/>
					<Route
						path="forgot-password"
						element={
							<BaseRoute
								element={<LoginPage type="forgot-password" />}
							/>
						}
					/>
					<Route
						path="reset-password/:token"
						element={
							<BaseRoute
								element={<LoginPage type="reset-password" />}
							/>
						}
					/>
					{/* Previously create-account and invite-account. Changing route so we can handle old routes
					at the tomcat filter level (for any stale links) */}
					<Route
						path="create-user/:token"
						element={
							<BaseRoute
								element={<LoginPage type="create-account" />}
							/>
						}
					/>
					<Route
						path="invite-user/:token"
						element={
							<BaseRoute
								element={<LoginPage type="invite-account" />}
							/>
						}
					/>
					<Route
						path="prompt-username/:promptId"
						element={
							<BaseRoute
								element={<LoginPage type="prompt-username" />}
							/>
						}
					/>
					<Route
						path="change-password"
						element={
							<ProtectedRoute
								exempt
								element={<LoginPage type="change-password" />}
							/>
						}
					/>
					<Route element={<PageOutlet setTheme={setThemeMode} />}>
						<Route
							path="notifications/*"
							element={
								<ProtectedRoute
									exempt
									element={<NotificationsPage />}
								/>
							}
						/>
						<Route
							path="home"
							element={<ProtectedRoute element={<Outlet />} />}
						>
							<Route index element={<OverviewPage />} />
						</Route>
						<Route
							path="admin"
							element={<ProtectedRoute element={<Outlet />} />}
						>
							<Route index element={<SecurityLevelNavigate />} />
							<Route path="overview" element={<OverviewPage />} />
							<Route
								path="users"
								element={<Navigate to="current" replace />}
							/>
							<Route
								path="users/:tab"
								element={<UsersTabsPage />}
							/>
							<Route path="products" element={<ProductsPage />} />
							<Route
								path="products/:application/:tenantId"
								element={<ProductDetailsPage />}
							/>
							<Route
								path="settings"
								element={<Navigate to="services" replace />}
							/>
							<Route
								path="settings/:tab"
								element={<SettingsPage />}
							/>
							<Route
								path="settings/services/okr/levels/:tenantGroupId/*"
								element={
									<ServiceSettingsContainer
										className="okrLevelsConfig"
										componentName="LevelsConfig"
										componentType="okrs"
										distFile="OKR.min.js"
										useLegacyLocale={true}
									/>
								}
							/>
							<Route
								path="settings/services/okr/custom-attributes/:tenantGroupId/*"
								element={
									<ServiceSettingsContainer
										className="okrCustomAttributes"
										componentName="CustomAttributes"
										componentType="okrs"
										distFile="OKR.min.js"
										useLegacyLocale={true}
									/>
								}
							/>
							<Route
								path="settings/services/okr/objective-configurations/:tenantGroupId/*"
								element={
									<ServiceSettingsContainer
										className="okrObjectiveConfigurations"
										componentName="ObjectiveConfigurations"
										componentType="okrs"
										distFile="OKR.min.js"
										useLegacyLocale={true}
									/>
								}
							/>
							<Route
								path="settings/services/launchpad/config/:tenantGroupId/*"
								element={
									<ServiceSettingsContainer
										className="launchpadAdmin"
										componentName="AdminScreen"
										componentType="launchpad"
										distFile="LaunchPad.min.js"
										fullPage
										useLegacyLocale={false}
									/>
								}
							/>
							<Route
								path="settings/services/logbook/config/:tenantGroupId/*"
								element={
									<ServiceSettingsContainer
										className="logBook"
										componentName="Configure"
										componentType="logbook"
										distFile="Logbook.min.js"
										fullPage
										useLegacyLocale={true}
									/>
								}
							/>
							<Route
								path="settings/services/teams/config/:tenantGroupId/*"
								element={
									<ServiceSettingsContainer
										className="groups"
										componentName="Teams"
										componentType="groups"
										distFile="Groups.min.js"
										fullPage
										useLegacyLocale={true}
									/>
								}
							/>
							<Route path="*" element={<InvalidNavigate />} />
						</Route>
						<Route
							path="pvadmin"
							element={
								<PVAdminProtectedRoute
									element={<PVAdminPage />}
								/>
							}
						>
							<Route index element={<OrganizationsPage />} />
							<Route
								path="orgs/:customerId/:tab?"
								element={<OrganizationInfoPage />}
							/>
						</Route>
					</Route>
					<Route
						path="*"
						element={
							<BaseRoute element={<Navigate to={BASE_PATH} />} />
						}
					/>
				</Routes>
			</BrowserRouter>
		</Wrapper>
	);
};

export default App;
