import React, { useContext, useState } from 'react';
import { get } from '../../../../hooks/request/request';
import { Modal } from '@planview/pv-uikit';
import styled from 'styled-components';
import { spacingPx, text } from '@planview/pv-utilities';
import { ButtonGhost } from '../../../../components/common/button/Button';
import { HBox } from '../../../../components/common/Layout';
import { UserContext, UserContextProps } from '../../../../context';
import { defineMessages, useIntl } from 'react-intl';
import { Input } from '@planview/pv-form';
import { CustomerResultDto } from '../../../../types/api/customers';
import { GridResultDto } from '../../../../types/api/api';

const DetailRow = styled.div`
	${text.regular};
	padding: 0 ${spacingPx.small} ${spacingPx.xsmall} ${spacingPx.small};
`;

const SearchTextInput = styled(Input)`
	width: 250px;
`;

const StyledHbox = styled(HBox)`
	gap: ${spacingPx.small};
`;

const messages = defineMessages({
	searchLabel: {
		id: 'pvadmin.org.findorg.search',
		description: 'Placeholder for search input to find org',
		defaultMessage: 'Org name, tenant ID, URL, etc',
	},
});

const hasAccessToCustomer = (
	customer: CustomerResultDto,
	userContext: UserContextProps,
) => {
	if (customer.id === 'PLANVIEW') {
		return userContext.hasSuperAdminAccess();
	}
	return true;
};

const getOrgUrl = (customer: CustomerResultDto) => {
	return `/pvadmin/orgs/${customer.id}/details`;
};

const FindOrgDialog = ({ onClose }: { onClose: () => void }) => {
	const intl = useIntl();
	const [searchText, setSearchText] = useState<string>('');
	const [displayMessage, setDisplayMessage] = useState<string | null>(null);
	const [foundCustomers, setFoundCustomers] = useState<
		CustomerResultDto[] | null
	>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const userContext = useContext(UserContext);

	const isValid = () => searchText.length > 0;

	const lookupOrgs = async () => {
		if (isValid()) {
			setLoading(true);
			setDisplayMessage(null);
			setFoundCustomers(null);
			const searchTextEncoded = encodeURIComponent(searchText);
			const result = await get<GridResultDto<CustomerResultDto>>(
				`/io/v1/admin/customer/search?q=${searchTextEncoded}`,
			);

			const customers = result.results;
			if (customers?.length) {
				setFoundCustomers(customers);
			} else {
				setDisplayMessage('Customer not found.');
			}
			setLoading(false);
		}
	};

	const handleOnKeyDown = (event: React.KeyboardEvent) => {
		if (
			event.key === 'Enter' ||
			(event.key === 'NumpadEnter' && isValid() && !loading)
		) {
			event.preventDefault();
			event.stopPropagation();
			void lookupOrgs();
		}
	};

	return (
		<Modal
			key="findOrgDialog"
			initialFocusId="email-input"
			confirmText="Close"
			headerText="Find organization / tenant"
			onConfirm={onClose}
			onCancel={onClose}
		>
			<StyledHbox>
				<SearchTextInput
					id="search-input"
					onChange={(value) => setSearchText(value)}
					onKeyDown={handleOnKeyDown}
					label={intl.formatMessage(messages.searchLabel)}
					selected
				/>
				<ButtonGhost
					message="Find"
					onClick={lookupOrgs}
					disabled={loading || !isValid()}
				/>
			</StyledHbox>
			{displayMessage}
			{foundCustomers?.map((customer, idx) => {
				const { title, id } = customer;
				return (
					<div data-testid="customer-details" key={idx}>
						<DetailRow>
							{hasAccessToCustomer(customer, userContext) ? (
								<a href={getOrgUrl(customer)}>{title}</a>
							) : (
								title
							)}
							&nbsp; ({id})
						</DetailRow>
					</div>
				);
			})}
		</Modal>
	);
};

const useFindOrgAction = () => {
	const [showDialog, setShowDialog] = useState(false);

	const findOrgAction = {
		text: 'Find organization or tenant',
		onActivate: () => {
			setShowDialog(true);
		},
	};

	const findOrgModal = showDialog && (
		<FindOrgDialog
			key="find-org-dialog"
			onClose={() => setShowDialog(false)}
		/>
	);

	const modals = [findOrgModal].filter(Boolean);
	return { findOrgAction, modals };
};

export default useFindOrgAction;
