import { ButtonEmpty, DropdownMenu, ListItem } from '@planview/pv-uikit';
import { DotsVertical } from '@planview/pv-icons';
import React from 'react';

export interface ActionProp {
	show?: boolean;
	disabled?: boolean;
	handler: () => void;
	text: string;
}

type ActionsMenuProps = {
	actions: ActionProp[];
	label: string;
};

const ActionsMenu = ({ actions, label }: ActionsMenuProps) => (
	<DropdownMenu
		label={label}
		alignRight={true}
		trigger={(triggerProps) => (
			<ButtonEmpty
				data-testid="trigger"
				activated={triggerProps['aria-expanded']}
				aria-label={label}
				icon={<DotsVertical />}
				{...triggerProps}
			/>
		)}
	>
		{actions
			.filter(({ show = true }) => show)
			.map(({ handler, text, ...rest }, idx) => (
				<ListItem
					data-testid="listItem"
					label={text}
					key={idx}
					{...rest}
					onActivate={handler}
				/>
			))}
	</DropdownMenu>
);
ActionsMenu.displayName = 'ActionsMenu';

export default ActionsMenu;
