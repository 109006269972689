import { defineMessages } from 'react-intl';

const messages = defineMessages({
	pageHeader: {
		id: 'admin.settings.organizationProfile.pageHeader',
		defaultMessage: 'Organization profile configuration',
		description: 'Header for the organization profile settings page',
	},
	pageDescription: {
		id: 'admin.settings.organizationProfile.pageDescription',
		defaultMessage:
			'Customize your organization’s branding display below in both light and dark mode. Light mode image will be used for dark mode if an image is not provided. This avatar is displayed within the product switcher when navigating between products.',
		description: 'Description for the organization profile settings page',
	},
	pageDescriptionDarkModeDisabled: {
		id: 'admin.settings.organizationProfile.pageDescriptionDarkModeDisabled',
		defaultMessage:
			'Customize your organization’s branding display below. This avatar is displayed within the product switcher when navigating between products.',
		description:
			'Description for the organization profile settings page when dark mode is disabled',
	},
	avatarHeader: {
		id: 'admin.settings.organizationProfile.avatarHeader',
		defaultMessage: 'AVATAR DISPLAY',
		description: 'Header for the avatar display section',
	},
	avatarDescription: {
		id: 'admin.settings.organizationProfile.avatarDescription',
		defaultMessage:
			'Image file should be in the format of PNG or JPG/JPEG. Required size: 250 x 250 pixels. Maximum file size: 1 MB.',
		description: 'Description for the avatar display section',
	},
	avatarLightLabel: {
		id: 'admin.settings.organizationProfile.avatarLightLabel',
		defaultMessage: 'Light mode',
		description: 'Label for the light mode avatar upload container',
	},
	avatarLightTooltip: {
		id: 'admin.settings.organizationProfile.avatarLightTooltip',
		defaultMessage:
			'Avatar displayed when using light mode in PV Admin. Defaults to dark mode avatar if light mode is not provided.',
		description: 'Tooltip for the light mode avatar upload container',
	},
	avatarLightTooltipDarkModeDisabled: {
		id: 'admin.settings.organizationProfile.avatarLightTooltipDarkModeDisabled',
		defaultMessage: 'Avatar displayed in PV Admin.',
		description:
			'Tooltip for the light mode avatar upload container when dark mode is disabled',
	},
	avatarDarkLabel: {
		id: 'admin.settings.organizationProfile.avatarDarkLabel',
		defaultMessage: 'Dark mode',
		description: 'Label for the dark mode avatar upload container',
	},
	avatarDarkTooltip: {
		id: 'admin.settings.organizationProfile.avatarDarkTooltip',
		defaultMessage:
			'Avatar displayed when using dark mode in PV Admin. Defaults to light mode avatar if dark mode is not provided.',
		description: 'Tooltip for the dark mode avatar upload container',
	},
	avatarUploadButton: {
		id: 'admin.settings.organizationProfile.avatarUploadButton',
		defaultMessage: 'Upload image',
		description: 'Button label for uploading a new avatar',
	},
	avatarUpdateButton: {
		id: 'admin.settings.organizationProfile.avatarUpdateButton',
		defaultMessage: 'Update',
		description: 'Button label for updating the current avatar',
	},
	avatarRemoveButton: {
		id: 'admin.settings.organizationProfile.avatarRemoveButton',
		defaultMessage: 'Remove',
		description: 'Button label for removing the current avatar',
	},
	avatarNotUploaded: {
		id: 'admin.settings.organizationProfile.avatarNotUploaded',
		defaultMessage: 'No image uploaded',
		description: 'Text displayed when no avatar image is uploaded',
	},
	avatarScanSuccess: {
		id: 'admin.settings.organizationProfile.avatarScanSuccess',
		defaultMessage: 'Upload successful',
		description: 'Text displayed for successful avatar scan',
	},
	avatarScanFailure: {
		id: 'admin.settings.organizationProfile.avatarScanFailure',
		defaultMessage: 'Upload failed - please try again',
		description: 'Text displayed for failed avatar scan',
	},
	avatarScanFailureMalware: {
		id: 'admin.settings.organizationProfile.avatarScanFailureMalware',
		defaultMessage: 'Upload failed - malware detected',
		description: 'Text displayed when malware detected during avatar scan',
	},
	avatarScanFailureMalwareTooltip: {
		id: 'admin.settings.organizationProfile.avatarScanFailureMalwareTooltip',
		defaultMessage:
			'Malware was detected in the most recent upload.{br}Please try again with another file.',
		description:
			'Tooltip message displayed when hovering over error icon if malware detected during avatar scan. {br} represents a line break',
	},
	avatarMalwareDetectedWarning: {
		id: 'admin.settings.organizationProfile.avatarMalwareDetectedWarning',
		defaultMessage: 'Malware was found in one or more uploaded files.',
		description:
			'Toast message displayed when malware detected during avatar scan',
	},
	avatarUploadSuccess: {
		id: 'admin.settings.organizationProfile.avatarUploadSuccess',
		defaultMessage:
			'Upload successful. Changes may take some time to appear.',
		description: 'Notification message for successful avatar upload',
	},
	avatarDeleteSuccess: {
		id: 'admin.settings.organizationProfile.avatarDeleteSuccess',
		defaultMessage: 'Organization avatar successfully removed.',
		description: 'Notification message for successful avatar removal',
	},
	profileDetailsHeader: {
		id: 'admin.settings.organizationProfile.profileDetailsHeader',
		defaultMessage: 'PROFILE DETAILS',
		description: 'Header for the profile details section',
	},
	customerSupport: {
		id: 'admin.settings.organizationProfile.customerSupport',
		description: 'Text to display for customer care support url',
		defaultMessage: 'Customer Support',
	},
	profileDetailsDescription: {
		id: 'admin.settings.organizationProfile.profileDetailsDescription',
		defaultMessage:
			'Organization name and domain can be updated by contacting {CustomerSupportLink}.',
		description:
			'Description for the profile details section. {CustomerSupportLink} represents the hyperlink that leads to the customer care support site.',
	},
	organizationNameLabel: {
		id: 'admin.settings.organizationProfile.organizationNameLabel',
		defaultMessage: 'Organization name',
		description: 'Label for the Organization name input field',
	},
	domainLabel: {
		id: 'admin.settings.organizationProfile.domainLabel',
		defaultMessage: 'Domain',
		description: 'Label for the Domain input field',
	},
});

export default messages;
