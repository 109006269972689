import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import messages from './ActionsMenu.messages';
import { DotsVertical } from '@planview/pv-icons';
import { ListItem } from '@planview/pv-uikit';
import { ToolbarDropdownMenu } from '@planview/pv-toolbar';
import { Action } from '../../../types/menu';

/**
 * @param rowData the row data for which the actions are to be shown
 * @param actions the array of action configurations
 * @param alignRight align the menu to the right of its trigger, defaults true
 * @param message optional message object to show instead of the vertical dots for the trigger
 * @param hideDisabled true(default) to hide disabled items. false will show all action items
 * @constructor the actions menu
 */
const ToolbarActionsMenu = <RowType,>({
	rowData,
	actions,
	alignRight = true,
	message = null,
	hideDisabled = true,
}: {
	rowData: RowType[];
	actions: Action<RowType>[];
	alignRight: boolean;
	message: MessageDescriptor | null;
	hideDisabled: boolean;
}): React.JSX.Element | null => {
	const intl = useIntl();

	const trigger =
		message != null
			? { label: intl.formatMessage(message), withCaret: true }
			: {
					tooltip: intl.formatMessage(messages.actionsToolTip),
					'aria-label': intl.formatMessage(messages.rowActionsLabel),
					icon: <DotsVertical />,
				};

	return actions.length ? (
		<>
			<ToolbarDropdownMenu
				alignRight={alignRight}
				trigger={trigger}
				label={message ? intl.formatMessage(message) : ''}
			>
				<>
					{
						actions
							.map((action) => {
								const isEnabled = action.isEnabled(rowData);
								const isHiddenOverride =
									action.isHidden && action.isHidden(rowData);
								if (
									(hideDisabled && !isEnabled) ||
									isHiddenOverride
								) {
									return null;
								}

								return (
									<ListItem
										key={action.message.id}
										label={intl.formatMessage(
											action.message,
										)}
										icon={action.icon || undefined}
										disabled={!isEnabled}
										onActivate={() =>
											action.activateFn(rowData)
										}
									/>
								);
							})
							.filter((item) => item) // remove nulls
					}
				</>
			</ToolbarDropdownMenu>
		</>
	) : null;
};

ToolbarActionsMenu.displayName = 'ToolbarActionsMenu';
export default ToolbarActionsMenu;
