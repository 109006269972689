import { defineMessages } from 'react-intl';

const messages = defineMessages({
	confirmationPrompt: {
		id: 'admin.addToBusinessUnit.dialog.confirmation.prompt',
		defaultMessage:
			'Do you want to add them to this business unit? You would inherit the profile of the user in the other business unit.',
		description: 'Confirmation text in the addToBusinessUnit modal.',
	},
	cancel: {
		id: 'admin.addToBusinessUnit.dialog.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel text in the addToBusinessUnit modal',
	},
});

export default messages;
