import { FormattedMessage, useIntl } from 'react-intl';
import messages from './NotificationsSettings.messages';
import { SIZE_XLARGE, Spinner, Switch } from '@planview/pv-uikit';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { sizePx, spacingPx, text } from '@planview/pv-utilities';
import { VBox } from '../../../components/common/Layout';
import { HelpIcon } from './SSOSettings';
import { put, requestWithErrorHandling } from '../../../hooks/request/request';
import { AppContext } from '../../../context';
import { ConfirmationModal } from '../../../components/common/modal/Modal';
import { ApiResponse } from '../../../types/api/api';
import { ToastType } from '../../../types/toast';
import {
	LookupTypeName,
	PolicyFormat,
	SamlRequestedAttribute,
} from '../../../types';

type SaveConfirmationDialogProps = {
	message: string;
	onConfirm: () => void;
	onCancel: () => void;
	isSaving: boolean;
};

type ConfigProps = {
	id: string;
	title: string;
	topDownUserManagementEnabled: boolean;
	enableSSO: boolean;
	enableUrl: boolean;
	enableXml: boolean;
	enableEmail: boolean;
	metadataXml: string;
	metadataUrl: string;
	metadataAttribute: string;
	samlSigningAlgorithm: string;
	samlNameIdLookupType: LookupTypeName;
	samlNameIDPolicyFormat: PolicyFormat;
	samlRequestedAttributes: SamlRequestedAttribute[];
	samlEntityId: string;
	samlAcsUrl: string;
	tokenExpiration: string;
	spMetadataUrl: string;
};

const Section = styled(VBox)`
	flex-basis: auto;
	line-height: ${sizePx.small};
	padding: ${spacingPx.large};
`;

const SectionDiv = styled.div`
	${text.regular};
	padding: 0 ${spacingPx.small} ${spacingPx.medium} ${spacingPx.small};
`;

const HeaderDiv = styled.div`
	display: flex;
	align-items: center;
	${text.h2};
	padding: 0 ${spacingPx.small} ${spacingPx.large} ${spacingPx.small};
`;

const SaveConfirmationDialog = ({
	message,
	onConfirm,
	onCancel,
	isSaving,
}: SaveConfirmationDialogProps) => {
	const intl = useIntl();

	return (
		<ConfirmationModal
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={
				isSaving
					? intl.formatMessage(messages.yesButtonWhileSaving)
					: intl.formatMessage(messages.yesButton)
			}
			headerText={intl.formatMessage(messages.saveHeader)}
			disableConfirm={isSaving}
			message={message}
		/>
	);
};

const NotificationsSettings = () => {
	const intl = useIntl();
	const appContext = useContext(AppContext);

	const [loading, setLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [enableEmail, setEnableEmail] = useState(false);
	const [config, setConfig] = useState<ConfigProps>();
	const [showEnableDialog, setShowEnableDialog] = useState(false);

	const toggleEmails = async (enabled: boolean) => {
		const data = { ...config, enableEmail: enabled };

		const { success, message } = (await put(
			`/io/v1/customer/details`,
			data,
		)) as ApiResponse;

		appContext.showToast({
			message: message,
			type: success ? ToastType.SUCCESS : ToastType.DANGER,
		});

		return success;
	};

	async function getSettings() {
		const { success, ...config } = await requestWithErrorHandling({
			method: 'get',
			url: `/io/v1/customer/details`,
			appContext,
			intl,
		});

		const configuration = config as ConfigProps;
		if (success) {
			setConfig(configuration);
			setEnableEmail(configuration.enableEmail);
		}
		setLoading(false);
	}

	useEffect(() => {
		void getSettings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return loading ? (
		<Spinner size={SIZE_XLARGE} />
	) : (
		<Section>
			<HeaderDiv>
				<FormattedMessage {...messages.notificationHeader} />
				{HelpIcon(
					intl.formatMessage(messages.notificationHeaderTooltip),
				)}
			</HeaderDiv>
			<SectionDiv>
				<FormattedMessage
					{...messages.notificationsInstructions}
					values={{
						a: (...chunks) => (
							<a
								href="https://success.planview.com/Planview_Admin/Planview_Admin_Settings/Email_Settings"
								target="_blank"
								rel="noopener noreferrer"
							>
								{chunks}
							</a>
						),
					}}
				/>
			</SectionDiv>
			<SectionDiv>
				<Switch
					checked={enableEmail}
					label={intl.formatMessage(
						messages.sendInvitationEmailsLabel,
					)}
					onChange={(enabled) => {
						setEnableEmail(enabled);
						setShowEnableDialog(true);
					}}
				/>
			</SectionDiv>

			{showEnableDialog ? (
				<SaveConfirmationDialog
					isSaving={isSaving}
					message={
						enableEmail
							? intl.formatMessage(messages.enableEmailContent)
							: intl.formatMessage(messages.disableEmailContent)
					}
					onCancel={() => {
						setShowEnableDialog(false);
						setEnableEmail(!enableEmail);
					}}
					onConfirm={async () => {
						setIsSaving(true);
						await toggleEmails(enableEmail);
						setShowEnableDialog(false);
						setIsSaving(false);
					}}
				/>
			) : null}
		</Section>
	);
};

export default NotificationsSettings;
