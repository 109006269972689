import React, { useContext, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Modal, Radio } from '@planview/pv-uikit';
import Grid from '../../../components/common/grid/Grid';
import { GridSearchInput } from '../../../components/common/input/GridSearchInput';
import { HBox, PhoneMaxWidth } from '../../../components/common/Layout';
import { post } from '../../../hooks/request/request';
import { AppContext, UserContext } from '../../../context';
import messages from './MapUsersDialog.messages';
import { useLocalStoragePreferences } from '@planview/pv-grid';
import { spacingPx, text } from '@planview/pv-utilities';
import { ToastType } from '../../../types/toast';
import serverMessages from '../../../messages/server';

const HBoxLeft = styled(HBox)`
	@media only screen and (max-width: ${PhoneMaxWidth}px) {
		justify-content: flex-start;
	`;

const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	height: 600px;
	${text.regular};
	padding: ${spacingPx.small};
`;

const MapLabel = styled.div`
	min-width: 50px;
	padding-right: ${spacingPx.xsmall};
`;
const ToLabel = styled.div`
	min-width: 33px;
	padding-right: ${spacingPx.xsmall};
	line-height: 40px;
`;
const ExistingAccountLabel = styled.div`
	margin-left: 55px;
	margin-bottom: ${spacingPx.small};
`;
const GridContainer = styled.div`
	margin-left: 50px;
	overflow: hidden;
	flex-grow: 1;
`;
const StyledSearchInput = styled(GridSearchInput)`
	margin-left: 50px;
`;

const MAP_MODE = {
	NONE: 'NONE',
	TO_NEW_USER: 'TO_NEW_USER',
	TO_EXISTING_USER: 'TO_EXISTING_USER',
};

const MappedUserFullName = ({ user }) => {
	const userContext = useContext(UserContext);
	return user ? <div>{userContext.getUserFullName(user)}</div> : null;
};

const getUsersGridColumns = (intl, enableUsernames) => {
	const { formatMessage } = intl;
	const columns = [
		{
			id: 'firstName',
			label: formatMessage(messages.firstNameColumn),
			width: 150,
		},
		{
			id: 'lastName',
			label: formatMessage(messages.lastNameColumn),
			width: 150,
		},
		{
			id: 'email',
			label: formatMessage(messages.emailColumn),
			width: 200,
		},
	];

	if (enableUsernames) {
		columns.push({
			id: 'username',
			label: formatMessage(messages.usernameColumn),
			width: 200,
		});
	}

	return columns;
};

/**
 * Dialog used on the Products > Unmapped users tab to allow mapping a tenant
 * user to an existing Planview user, or start process of creating a new user
 *
 * @return {Modal} the modal
 */
const MapUsersDialog = ({
	app,
	onCancel,
	onConfirm,
	showNewUserDialog,
	user,
}) => {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const { formatMessage } = intl;
	const [mapMode, setMapMode] = useState(MAP_MODE.NONE);
	const [selectedUserId, setSelectedUserId] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [isSaving, setSaving] = useState(false);
	const grid = useRef();

	const { enableUsernames } = appContext.featureFlags;
	const columns = getUsersGridColumns(intl, enableUsernames);

	const preferencesKey = columns.map((c) => c.id).join('-');
	const preferencesAdapter = useLocalStoragePreferences(preferencesKey);

	const onSelectionChange = (items) => {
		const item = items[0];
		setMapMode(MAP_MODE.TO_EXISTING_USER);
		setSelectedUserId(item ? item.id : null);
	};

	const selectNewUserRadio = () => {
		grid.current.clearSelection();
		setMapMode(MAP_MODE.TO_NEW_USER);
		setSelectedUserId(null);
	};

	const mapUser = async () => {
		if (mapMode === MAP_MODE.TO_NEW_USER) {
			showNewUserDialog();
		} else if (mapMode === MAP_MODE.TO_EXISTING_USER) {
			setSaving(true);
			const { success, message } = await post(
				`/io/v1/user/mapExisting/${app.envSelectorEncodedString}`,
				{
					unmappedUserId: user.id,
					id: selectedUserId,
				},
				false,
			);
			setSaving(false);

			appContext.showToast({
				message:
					message || formatMessage(serverMessages.unexpectedError),
				type: success ? ToastType.SUCCESS : ToastType.DANGER,
			});

			if (onConfirm) {
				onConfirm();
			}
		}
	};

	return (
		<Modal
			id="map-user-modal"
			onConfirm={mapUser}
			onCancel={onCancel}
			confirmText={formatMessage(messages.mapButton)}
			headerText={formatMessage(messages.header)}
			disableConfirm={isSaving || mapMode === MAP_MODE.NONE}
			size={enableUsernames ? '875px' : ''}
		>
			<ModalBody>
				<HBoxLeft flex={0} data-testid={'map-user'}>
					<MapLabel>{formatMessage(messages.mapText)}</MapLabel>
					<MappedUserFullName user={user} />
				</HBoxLeft>
				<HBoxLeft flex={0} data-testid={'to-user'}>
					<ToLabel>{formatMessage(messages.toText)}</ToLabel>
					<Radio
						value={'newMapping'}
						label={formatMessage(messages.newPlanviewAccount)}
						selected={mapMode === MAP_MODE.TO_NEW_USER}
						onChange={selectNewUserRadio}
					/>
				</HBoxLeft>
				<ExistingAccountLabel>
					{formatMessage(messages.existingPlanviewAccount)}
				</ExistingAccountLabel>
				<StyledSearchInput
					value={searchValue}
					onChange={(searchQuery) => {
						setSearchValue(searchQuery);
					}}
					triggerSearch={(searchQuery) => {
						grid.current.triggerSearch(searchQuery);
					}}
				/>
				<GridContainer>
					<Grid
						url={'/io/v1/user?deactivated=false'}
						columns={columns}
						selectionType="radio"
						width={550}
						innerRef={(ref) => {
							grid.current = ref;
						}}
						onSelectionChange={onSelectionChange}
						preferencesAdapter={preferencesAdapter}
						clearFilters={() => {
							setSearchValue('');
							grid.current.resetFiltering();
						}}
						actionColumn={false}
					/>
				</GridContainer>
			</ModalBody>
		</Modal>
	);
};

export default MapUsersDialog;
