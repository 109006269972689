import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { AVATAR_SIZE_XLARGE } from '@planview/pv-uikit';
import { AppContext, UserContext } from '../../../context';
import { default as BaseAvatar } from '../../../components/common/avatar/Avatar';
import { Link } from '../../../components/common/Link';
import { Section, SectionTitle } from '../../../components/home/Section';
import { theme, spacingPx } from '@planview/pv-utilities';
import messages from './ProfileAndLinksSection.messages';
import { useLogout } from '../../../hooks/useLogout';

const Container = styled.div<{ flex?: boolean }>`
	flex-grow: 1;
	${(props) =>
		`flex-grow: ${typeof props.flex !== 'undefined' ? props.flex : 1};`}
`;

const Avatar = styled(BaseAvatar)`
	margin: ${spacingPx.small} auto ${spacingPx.small} auto;
`;

const QuickLink = styled(Link)`
	color: ${theme.textLinkNormal};
	margin-top: ${spacingPx.xsmall};
	padding-left: ${spacingPx.small};
`;

const LinkElement = styled.a`
	margin-top: ${spacingPx.xsmall};
	color: ${theme.textLinkNormal};
	display: block;
	text-decoration: none;
	padding-left: ${spacingPx.small};

	&:hover {
		cursor: pointer;
		text-decoration: underline;
		color: ${theme.textLinkHover};
	}
`;

const ContentDiv = styled.div`
	text-align: center;
`;

export const ProfileSection = () => {
	const { user, customer } = useContext(UserContext);
	const { featureFlags } = useContext(AppContext);
	const { name, email, username } = user;
	const { enableCustomerDomain, enableUsernames } = featureFlags;
	return (
		<Section data-testid="profile-section">
			<SectionTitle>
				<FormattedMessage {...messages.section} />
			</SectionTitle>
			<Avatar size={AVATAR_SIZE_XLARGE} />
			<ContentDiv>{name}</ContentDiv>
			<ContentDiv>{email}</ContentDiv>
			{enableUsernames && <ContentDiv>{username}</ContentDiv>}
			{enableCustomerDomain && (
				<ContentDiv>
					<FormattedMessage
						{...messages.orgDomainLabel}
						values={{ domain: customer.domain }}
					/>
				</ContentDiv>
			)}
		</Section>
	);
};

export const QuickLinksSection = () => {
	const userContext = useContext(UserContext);
	const { logout } = useLogout();
	const canChangePassword =
		userContext.user.passwordUser && !userContext.hasImpersonator();

	return (
		<Section data-testid="links-section">
			<SectionTitle>
				<FormattedMessage {...messages.quickLinks} />
			</SectionTitle>

			{canChangePassword && (
				<QuickLink to={{ pathname: '/change-password' }}>
					<FormattedMessage {...messages.changePassword} />
				</QuickLink>
			)}

			<LinkElement onClick={() => logout()}>
				<FormattedMessage {...messages.logout} />
			</LinkElement>
		</Section>
	);
};

const ProfileAndLinksSection = () => (
	<Container>
		<ProfileSection />
		<QuickLinksSection />
	</Container>
);

export default ProfileAndLinksSection;
