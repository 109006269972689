import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Combobox, Form } from '@planview/pv-form';
import { ButtonPrimary } from '../../../../components/common/button/Button';
import { ButtonContainer, Header } from '../../../../components/common/Layout';
import { get, post } from '../../../../hooks/request/request';
import messages from '../login/LoginForm.messages';
import { AppContext } from '../../../../context';
import { ToastType } from '../../../../types/toast';
import serverMessages from '../../../../messages/server';
import {
	LoginNextStepDto,
	PromptUsernameDto,
} from '../../../../types/api/login';
import { ComboboxOption } from '@planview/pv-uikit';
import { useParams } from 'react-router-dom';

type PromptUsernameFormParams = {
	promptId: string;
};

const PromptUsernameForm = () => {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const { promptId = '' } = useParams<PromptUsernameFormParams>();

	const [loading, setLoading] = useState(true);
	const [usernameOptions, setUsernameOptions] = useState<ComboboxOption[]>(
		[],
	);
	const [selectedUsernameOption, setSelectedUsernameOption] =
		useState<ComboboxOption | null>(null);

	const getNextStep = async (username: string) => {
		const { success, message, nextStep, data } = (await post(
			`/api/loginsso/prompt/${promptId}`,
			{
				username,
			},
			true,
		)) as LoginNextStepDto;
		if (!success) {
			// Any errors while checking for username (network error, service down etc.)
			const errorMessage =
				message || intl.formatMessage(serverMessages.unexpectedError);
			appContext.showToast({
				message: errorMessage,
				type: ToastType.DANGER,
			});
		}
		return { nextStep, data };
	};

	const checkNextStep = async (username: string) => {
		const { data } = await getNextStep(username);

		setLoading(false);

		// Send to the redirectUrl if the user is configured for SSO
		window.location.replace(data.redirectUrl);
	};

	const handleSubmit = async () => {
		if (selectedUsernameOption) {
			setLoading(true);
			await checkNextStep(String(selectedUsernameOption.value));
		}
	};

	const onUsernameChange = (option: ComboboxOption | null) => {
		setSelectedUsernameOption(option);
	};

	const getUsernames = async (promptId: string) => {
		const { success, message, usernames } = (await get(
			`/api/loginsso/prompt/${promptId}`,
		)) as PromptUsernameDto;
		if (!success) {
			// Any errors while checking for username (network error, service down etc.)
			const errorMessage =
				message || intl.formatMessage(serverMessages.unexpectedError);
			appContext.showToast({
				message: errorMessage,
				type: ToastType.DANGER,
			});
		} else {
			const usernameOptions: ComboboxOption[] = usernames.map(
				(username) => ({
					label: username,
					value: username,
				}),
			);

			setUsernameOptions(usernameOptions);
			if (usernameOptions.length) {
				setSelectedUsernameOption(usernameOptions[0]);
			}
			setLoading(false);
		}
	};

	useEffect(() => {
		void getUsernames(promptId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Form
			label={intl.formatMessage(messages.formHeader)}
			onSubmit={(event) => {
				event.preventDefault();
				event.stopPropagation();
				void handleSubmit();
			}}
		>
			<Header>
				<FormattedMessage {...messages.formHeader} />
			</Header>

			<Combobox
				label={intl.formatMessage(messages.username)}
				options={usernameOptions}
				clearable={false}
				value={selectedUsernameOption}
				onChange={onUsernameChange}
			/>

			<ButtonContainer>
				<ButtonPrimary
					fluid
					type="submit"
					loading={loading}
					message={messages.signIn}
				/>
			</ButtonContainer>
		</Form>
	);
};

export default PromptUsernameForm;
