import React, { useState } from 'react';
import { PlusCircle } from '@planview/pv-icons';
import messages from '../UsersPage.messages';
import { User } from '../../../../types';
import AddProductsDialog from '../../newUserDialog/AddProductsDialog';
import { Action } from '../../../../types/menu';

const useAddProductEnvsAction = (isCustomerCare: boolean) => {
	const [showAddProductEnvsModal, setShowAddProductEnvsModal] =
		useState(false);
	const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

	const addProductEnvsAction: Action<User> = {
		icon: <PlusCircle />,
		message: messages.addProductEnvironmentsHeader,
		isEnabled: (users: User[]) => users.length > 0,
		activateFn: (users: User[]) => {
			setSelectedUsers(users);
			setShowAddProductEnvsModal(true);
		},
	};

	const addProductEnvsModal = showAddProductEnvsModal ? (
		<AddProductsDialog
			key="addProductsEnvsModal"
			onConfirm={() => setShowAddProductEnvsModal(false)}
			onCancel={() => setShowAddProductEnvsModal(false)}
			isCustomerCare={isCustomerCare}
			selectedUsers={selectedUsers}
		/>
	) : null;

	return { addProductEnvsAction, addProductEnvsModal };
};

export default useAddProductEnvsAction;
