import React, { createRef, useContext, useEffect, useState } from 'react';
import { ButtonGhost } from '../../../../components/common/button/Button';
import { Label } from '@planview/pv-form';
import styled from 'styled-components';
import { PageHeader } from '../../../../components/common/Wrappers';
import {
	borderRadius,
	color,
	iconSizes,
	sizePx,
	spacingPx,
	text,
	theme,
} from '@planview/pv-utilities';
import { HBox, VBox } from '../../../../components/common/Layout';
import {
	del,
	post,
	requestWithErrorHandling,
} from '../../../../hooks/request/request';
import {
	Avatar,
	Spinner,
	AVATAR_SIZE_XLARGE,
	SIZE_LARGE,
	Input,
	Tooltip,
} from '@planview/pv-uikit';
import { AppContext, AppContextProps, UserContext } from '../../../../context';
import { ToastType } from '../../../../types/toast';
import { ApiResponse } from '../../../../types/api/api';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import messages from './OrganizationProfileSettings.messages';
import { ShowToastFn } from '../../../../context/appContext';
import { Cobrand } from '../../../../types/api/images';
import RemoveAvatarModal from './RemoveAvatarModal';
import { CheckmarkCircleFilled, IssueFilled } from '@planview/pv-icons';

const ACCEPTED_FILE_TYPES = 'image/png, image/jpeg, image/svg+xml';
const DARK = 'dark';
const NO_THREATS_FOUND = 'NO_THREATS_FOUND';
const THREATS_FOUND = 'THREATS_FOUND';

const HeaderDiv = styled.div`
	display: flex;
	align-items: center;
	${text.h2};
	padding: 0 ${spacingPx.small} ${spacingPx.large} ${spacingPx.small};
`;

const Section = styled(VBox)`
	flex-basis: auto;
	line-height: ${sizePx.small};
	padding: ${spacingPx.medium};
`;

const SectionLabel = styled.div`
	padding-left: ${spacingPx.small};
	padding-bottom: ${spacingPx.small};
`;

const SectionDiv = styled.div`
	${text.regular}
	padding: 0 ${spacingPx.small};
	margin-bottom: ${spacingPx.medium};
`;

const ImageUploadContainer = styled.div`
	margin-right: ${spacingPx.xlarge};
`;

const ImageContainer = styled.div<{ $color?: string | null }>`
	${borderRadius.medium}
	border: ${({ $color }) => ($color ? 'none' : '1px dashed')};
	background-color: ${({ $color }) => ($color ? $color : 'transparent')};
	height: ${sizePx.large};
	width: ${sizePx.large};
`;

const StyledHBox = styled(HBox)<{ $color?: string }>`
	align-items: center;
	color: ${({ $color }) => $color};
`;

const Url = styled.a`
	text-decoration: none;
	display: inline;
	color: ${theme.textLinkNormal} !important;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
		color: ${theme.textLinkHover};
	}
`;

const ButtonContainer = styled.div`
	padding-left: ${spacingPx.small};
`;

const MissingImageTextContainer = styled.div`
	color: #888888;
	margin-top: ${spacingPx.small};
`;

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const getCobrand = async (
	intl: IntlShape,
	appContext: AppContextProps,
	setCobrand: React.Dispatch<React.SetStateAction<Cobrand>>,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
	const { success, ...cobrand } = await requestWithErrorHandling<Cobrand>({
		method: 'get',
		url: `/io/v1/image/cobrand`,
		intl,
		appContext,
	});

	if (success) {
		setCobrand(cobrand);
	}

	const { scanStatus, darkScanStatus } = cobrand;
	if ([scanStatus, darkScanStatus].includes(THREATS_FOUND)) {
		appContext.showToast({
			message: intl.formatMessage(messages.avatarMalwareDetectedWarning),
			type: ToastType.DANGER,
		});
	}

	setIsLoading(false);
};

const uploadCobrand = async (
	file: File,
	intl: IntlShape,
	appContext: AppContextProps,
	setCobrand: React.Dispatch<React.SetStateAction<Cobrand>>,
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
	theme?: string,
) => {
	const formData = new FormData();
	formData.append('image', file);

	const queryString = theme
		? `?${new URLSearchParams({ theme }).toString()}`
		: '';

	const { success, message } = (await post(
		`/io/v1/image/cobrand${queryString}`,
		formData,
		false,
		{
			'Content-Type': 'multipart/form-data',
		},
	)) as ApiResponse;

	appContext.showToast({
		message: success
			? intl.formatMessage(messages.avatarUploadSuccess)
			: message,
		type: success ? ToastType.SUCCESS : ToastType.DANGER,
	});

	await sleep(5000);
	await getCobrand(intl, appContext, setCobrand, setIsLoading);
};

const deleteCobrand = async (
	showToast: ShowToastFn,
	intl: IntlShape,
	theme: string,
) => {
	const queryString = theme
		? `?${new URLSearchParams({ theme }).toString()}`
		: '';

	const { success, message } = (await del(
		`/io/v1/image/cobrand${queryString}`,
	)) as ApiResponse;

	showToast({
		message: success
			? intl.formatMessage(messages.avatarDeleteSuccess)
			: message,
		type: success ? ToastType.SUCCESS : ToastType.DANGER,
	});

	return success;
};

const CobrandSection = () => {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(true);
	const [selectedMode, setSelectedMode] = useState('');
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [cobrand, setCobrand] = useState<Cobrand>({
		url: null,
		darkUrl: null,
		scanStatus: null,
		darkScanStatus: null,
	});
	const uploadLightRef = createRef<HTMLInputElement>();
	const uploadDarkRef = createRef<HTMLInputElement>();
	const { featureFlags, showToast } = appContext;
	const { enableDarkMode } = featureFlags;

	useEffect(() => {
		void getCobrand(intl, appContext, setCobrand, setIsLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const removeSelectedAvatar = () => {
		if (selectedMode === DARK) {
			setCobrand({
				...cobrand,
				darkUrl: null,
				darkScanStatus: null,
			});
		} else {
			setCobrand({
				...cobrand,
				url: null,
				scanStatus: null,
			});
		}
	};

	const { url, darkUrl, scanStatus, darkScanStatus } = cobrand;
	return (
		<>
			<HeaderDiv>
				<PageHeader>
					{intl.formatMessage(messages.pageHeader)}
				</PageHeader>
			</HeaderDiv>
			<SectionDiv>
				{enableDarkMode
					? intl.formatMessage(messages.pageDescription)
					: intl.formatMessage(
							messages.pageDescriptionDarkModeDisabled,
						)}
			</SectionDiv>
			<SectionLabel>
				{intl.formatMessage(messages.avatarHeader)}
			</SectionLabel>
			<SectionDiv>
				{intl.formatMessage(messages.avatarDescription)}
			</SectionDiv>
			<SectionDiv>
				{isLoading ? (
					<Spinner size={SIZE_LARGE} />
				) : (
					<HBox>
						<ImageUploadContainer>
							<Label
								iconTooltip={
									enableDarkMode
										? intl.formatMessage(
												messages.avatarLightTooltip,
											)
										: intl.formatMessage(
												messages.avatarLightTooltipDarkModeDisabled,
											)
								}
							>
								{intl.formatMessage(messages.avatarLightLabel)}
							</Label>
							<StyledHBox>
								<ImageContainer $color={url ? 'white' : null}>
									{url && (
										<Avatar
											size={AVATAR_SIZE_XLARGE}
											src={url || ''}
											data-testid="cobrand"
										/>
									)}
								</ImageContainer>
								{!url ? (
									<ButtonContainer>
										<ButtonGhost
											message={intl.formatMessage(
												messages.avatarUploadButton,
											)}
											onClick={() => {
												uploadLightRef.current?.click();
											}}
										/>
										{!url && (
											<MissingImageTextContainer>
												{intl.formatMessage(
													messages.avatarNotUploaded,
												)}
											</MissingImageTextContainer>
										)}
									</ButtonContainer>
								) : (
									<>
										<ButtonContainer>
											<ButtonGhost
												message={intl.formatMessage(
													messages.avatarUpdateButton,
												)}
												onClick={() => {
													uploadLightRef.current?.click();
												}}
											/>
										</ButtonContainer>
										<ButtonContainer>
											<ButtonGhost
												message={intl.formatMessage(
													messages.avatarRemoveButton,
												)}
												onClick={() => {
													setSelectedMode('');
													setShowRemoveModal(true);
												}}
											/>
										</ButtonContainer>
									</>
								)}
								<input
									id="upload-light-cobrand"
									data-testid="upload-light-cobrand"
									type="file"
									accept={ACCEPTED_FILE_TYPES}
									ref={uploadLightRef}
									hidden={true}
									onChange={async () => {
										const file =
											uploadLightRef.current?.files?.[0];
										if (file) {
											await uploadCobrand(
												file,
												intl,
												appContext,
												setCobrand,
												setIsLoading,
											);
										} else {
											console.log('No file selected');
										}
									}}
								/>
							</StyledHBox>
							{!scanStatus ? null : scanStatus ===
							  NO_THREATS_FOUND ? (
								<StyledHBox>
									{intl.formatMessage(
										messages.avatarScanSuccess,
									)}
									<CheckmarkCircleFilled
										color={theme.iconSuccess}
									/>
								</StyledHBox>
							) : (
								<StyledHBox $color={color.error500}>
									{scanStatus === THREATS_FOUND
										? intl.formatMessage(
												messages.avatarScanFailureMalware,
											)
										: intl.formatMessage(
												messages.avatarScanFailure,
											)}
									<Tooltip
										text={
											scanStatus === THREATS_FOUND &&
											intl.formatMessage(
												messages.avatarScanFailureMalwareTooltip,
												{
													br: <br />,
												},
											)
										}
									>
										<IssueFilled
											size={iconSizes.SIZE_SMALL}
										/>
									</Tooltip>
								</StyledHBox>
							)}
						</ImageUploadContainer>
						{enableDarkMode && (
							<ImageUploadContainer>
								<Label
									iconTooltip={intl.formatMessage(
										messages.avatarDarkTooltip,
									)}
								>
									{intl.formatMessage(
										messages.avatarDarkLabel,
									)}
								</Label>
								<StyledHBox>
									<ImageContainer
										$color={darkUrl ? '#303030' : null}
									>
										{darkUrl && (
											<Avatar
												size={AVATAR_SIZE_XLARGE}
												src={darkUrl || ''}
												data-testid="dark-cobrand"
											/>
										)}
									</ImageContainer>
									{!darkUrl ? (
										<ButtonContainer>
											<ButtonGhost
												message={intl.formatMessage(
													messages.avatarUploadButton,
												)}
												hidden={isLoading}
												onClick={() =>
													uploadDarkRef.current?.click()
												}
											/>
											{!darkUrl && (
												<MissingImageTextContainer>
													{intl.formatMessage(
														messages.avatarNotUploaded,
													)}
												</MissingImageTextContainer>
											)}
										</ButtonContainer>
									) : (
										<>
											<ButtonContainer>
												<ButtonGhost
													message={intl.formatMessage(
														messages.avatarUpdateButton,
													)}
													onClick={() => {
														uploadDarkRef.current?.click();
													}}
												/>
											</ButtonContainer>
											<ButtonContainer>
												<ButtonGhost
													message={intl.formatMessage(
														messages.avatarRemoveButton,
													)}
													onClick={() => {
														setSelectedMode(DARK);
														setShowRemoveModal(
															true,
														);
													}}
												/>
											</ButtonContainer>
										</>
									)}
									<input
										id="upload-dark-cobrand"
										data-testid="upload-dark-cobrand"
										type="file"
										accept={ACCEPTED_FILE_TYPES}
										ref={uploadDarkRef}
										hidden={true}
										onChange={async () => {
											const file =
												uploadDarkRef.current
													?.files?.[0];
											if (file) {
												await uploadCobrand(
													file,
													intl,
													appContext,
													setCobrand,
													setIsLoading,
													DARK,
												);
											} else {
												console.log('No file selected');
											}
										}}
									/>
								</StyledHBox>
								{!darkScanStatus ? null : darkScanStatus ===
								  NO_THREATS_FOUND ? (
									<StyledHBox>
										{intl.formatMessage(
											messages.avatarScanSuccess,
										)}
										<CheckmarkCircleFilled
											color={theme.iconSuccess}
										/>
									</StyledHBox>
								) : (
									<StyledHBox $color={color.error500}>
										{darkScanStatus === THREATS_FOUND
											? intl.formatMessage(
													messages.avatarScanFailureMalware,
												)
											: intl.formatMessage(
													messages.avatarScanFailure,
												)}
										<Tooltip
											text={
												darkScanStatus ===
													THREATS_FOUND &&
												intl.formatMessage(
													messages.avatarScanFailureMalwareTooltip,
													{
														br: <br />,
													},
												)
											}
										>
											<IssueFilled
												size={iconSizes.SIZE_SMALL}
											/>
										</Tooltip>
									</StyledHBox>
								)}
							</ImageUploadContainer>
						)}
					</HBox>
				)}
			</SectionDiv>
			{showRemoveModal && (
				<RemoveAvatarModal
					onCancel={() => setShowRemoveModal(false)}
					onConfirm={async () => {
						const success = await deleteCobrand(
							showToast,
							intl,
							selectedMode,
						);

						if (success) {
							removeSelectedAvatar();
						}

						setShowRemoveModal(false);
					}}
				/>
			)}
		</>
	);
};

const ProfileDetailsSection = () => {
	const intl = useIntl();
	const { customer } = useContext(UserContext);
	const { title, domain } = customer;
	return (
		<>
			<SectionLabel>
				{intl.formatMessage(messages.profileDetailsHeader)}
			</SectionLabel>
			<SectionDiv>
				<FormattedMessage
					{...messages.profileDetailsDescription}
					values={{
						CustomerSupportLink: (
							<Url
								target="_blank"
								rel="noopener noreferrer"
								href="https://success.planview.com/Planview_Communities_and_Programs/Customer_Care"
							>
								{intl.formatMessage(messages.customerSupport)}
							</Url>
						),
					}}
				/>
			</SectionDiv>
			<SectionDiv>
				<Label>
					{intl.formatMessage(messages.organizationNameLabel)}
				</Label>
				<Input value={title} disabled={true} />
			</SectionDiv>
			<SectionDiv>
				<Label>{intl.formatMessage(messages.domainLabel)}</Label>
				<Input value={domain} disabled={true} />
			</SectionDiv>
		</>
	);
};

const OrganizationProfileSettings = () => {
	return (
		<Section data-testid="org-profile-settings">
			<SectionDiv>
				<CobrandSection />
			</SectionDiv>
			<SectionDiv>
				<ProfileDetailsSection />
			</SectionDiv>
		</Section>
	);
};

export default OrganizationProfileSettings;
