import React, { useContext, useState } from 'react';
import { AppContext, UserContext } from '../../../../context';
import messages from '../UsersPage.messages';
import { useIntl } from 'react-intl';
import { post } from '../../../../hooks/request/request';
import { ConfirmationModal } from '../../../../components/common/modal/Modal';
import { Cancel } from '@planview/pv-icons';
import { DESTRUCTIVE } from '@planview/pv-uikit';
import { User } from '../../../../types';
import { CompositeStatusDtoResponse } from '../../../../types/api/users';
import { ToastType } from '../../../../types/toast';
import { Action } from '../../../../types/menu';

type DeleteConfirmationDialogProps = {
	users: User[];
	onCancel: () => void;
	onConfirm: () => void;
};

const DeleteConfirmationDialog = (props: DeleteConfirmationDialogProps) => {
	const { users, onCancel, onConfirm } = props;

	const intl = useIntl();
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { formatMessage } = intl;

	const { customer } = useContext(UserContext);
	const appContext = useContext(AppContext);
	const [disableConfirm, setDisableConfirm] = useState(false);

	const message = customer.topDownUserManagementEnabled
		? messages.deleteTdumUserConfirmation
		: users.length === 1
			? formatMessage(messages.deleteContent, { email: users[0].email })
			: formatMessage(messages.deleteContentPlural, {
					count: users.length,
				});

	const deleteUser = async () => {
		setDisableConfirm(true);
		const userIds = users.map(({ id }) => id);

		let result = (await post(`/io/v1/user/delete`, {
			userIds,
		})) as CompositeStatusDtoResponse;

		if (!result.success) {
			result = result.data as CompositeStatusDtoResponse;
		}
		const { successCount, failureCount, failureMessages } = result;
		if (successCount) {
			appContext.showToast({
				message:
					successCount > 1
						? intl.formatMessage(messages.deleteUserSuccessPlural, {
								count: successCount,
							})
						: intl.formatMessage(messages.deleteUserSuccess),
				type: ToastType.SUCCESS,
			});
		}

		if (failureCount) {
			failureMessages.forEach((message) => {
				appContext.showToast({ message, type: ToastType.DANGER });
			});
		}
		setDisableConfirm(false);
		onConfirm();
	};

	return (
		<ConfirmationModal
			type={DESTRUCTIVE}
			onConfirm={deleteUser}
			onCancel={onCancel}
			confirmText={formatMessage(messages.deleteButton)}
			cancelText={formatMessage(messages.noButton)}
			headerText={formatMessage(messages.deleteHeader)}
			message={message}
			disableConfirm={disableConfirm}
		/>
	);
};

const useDeleteUserAction = ({ refresh }: { refresh: () => void }) => {
	const userContext = useContext(UserContext);
	const [deleteUsers, setDeleteUsers] = useState<User[] | null>(null);

	const isCurrentLoggedInUser = (user: User) =>
		user.id === userContext.user.id;

	const deleteAction: Action<User> = {
		icon: <Cancel />,
		message: messages.deleteUserItem,
		isEnabled: (users: User[]) => {
			return users.length > 0 && !users.some(isCurrentLoggedInUser);
		},
		activateFn: (users: User[]) => setDeleteUsers(users),
	};

	const deleteModal = deleteUsers ? (
		<DeleteConfirmationDialog
			key="deleteDialog"
			users={deleteUsers}
			onCancel={() => {
				setDeleteUsers(null);
			}}
			onConfirm={() => {
				refresh();
				setDeleteUsers(null);
			}}
		/>
	) : null;

	return { deleteAction, deleteModal };
};

export default useDeleteUserAction;
