import React, { useContext } from 'react';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import {
	AppContext,
	AppContextProps,
	CustomerContext,
	UserContext,
} from '../../../../context';
import { getUserIds } from '../userUtils';
import messages from '../UsersPage.messages';
import { requestWithErrorHandling } from '../../../../hooks/request/request';
import { Refresh } from '@planview/pv-icons';
import { User } from '../../../../types';
import { ApiRequest } from '../../../../types/api/api';
import { UserIdsDto } from '../../../../types/api/users';
import { Action } from '../../../../types/menu';

/**
 * @param isCustomerCare whether we are in 'customer care' mode or not
 * @return the API URL to use for either resetting password or resending invite
 */
const getApiUrl = (isCustomerCare: boolean) => {
	let url = '/io/v1/user/resetPasswordOrResendInvite';
	// if the customer we're viewing doesn't match our own user, we're customer care and need that endpoint
	if (isCustomerCare) {
		url = '/io/v1/admin/user/resetPasswordOrResendInvite';
	}
	return url;
};

/**
 * The action to either reset the user(s) password or resend an invite to the user(s)
 * @param users the user(s) involved in teh action
 * @param intl the {@link IntlShape}
 * @param appContext the current {@link AppContext}
 * @param customerId the targeted customer's ID
 * @param isCustomerCare whether we are in 'customer care' mode or not
 * @param successMessage the {@link MessageDescriptor} to use in the singular case
 * @param successPluralMessage the {@link MessageDescriptor} to use in the plural case
 */
const resetPasswordOrResendInvite = async (
	users: User[],
	intl: IntlShape,
	appContext: AppContextProps,
	customerId: string,
	isCustomerCare: boolean,
	successMessage: MessageDescriptor,
	successPluralMessage: MessageDescriptor,
) => {
	const userIds: string[] = getUserIds(users);
	const url = getApiUrl(isCustomerCare);

	const infoMessage = intl.formatMessage(
		userIds.length === 1 ? successMessage : successPluralMessage,
		{
			email: users[0].email,
			count: userIds.length,
		},
	);

	const userIdsDto: UserIdsDto = { userIds, customerId };
	const apiRequest: ApiRequest = {
		method: 'post',
		url,
		dataObj: userIdsDto,
		appContext,
		intl,
		infoMessage,
	};
	const { method, dataObj } = apiRequest;

	await requestWithErrorHandling({
		method,
		url,
		dataObj,
		appContext,
		intl,
		infoMessage,
	});
};

const useResetPasswordOrResendInviteAction = (isCustomerCare: boolean) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const customerContext = useContext(CustomerContext);
	const currentLoggedInUserId = userContext.user.id;
	const customerId = isCustomerCare
		? customerContext.customer?.id
		: userContext.customer.id;

	const refreshIcon = isCustomerCare ? null : <Refresh />;

	const resetPasswordAction: Action<User> = {
		icon: refreshIcon,
		message: messages.resetPasswordItem,
		isEnabled: (users: User[]) => {
			return (
				users.length > 0 &&
				users.every(
					(user) =>
						userContext.hasPassword(user) &&
						user.isActive &&
						user.id !== currentLoggedInUserId,
				)
			);
		},
		activateFn: (users: User[]) =>
			resetPasswordOrResendInvite(
				users,
				intl,
				appContext,
				customerId,
				isCustomerCare,
				messages.resetPasswordSuccess,
				messages.resetPasswordSuccessPlural,
			),
	};

	const resendInviteAction: Action<User> = {
		icon: refreshIcon,
		message: messages.resendInviteItem,
		isEnabled: (users: User[]) => {
			return (
				users.length > 0 &&
				users.every(
					(user) =>
						userContext.hasPassword(user) &&
						!user.isActive &&
						user.id !== currentLoggedInUserId,
				)
			);
		},
		activateFn: (users: User[]) =>
			resetPasswordOrResendInvite(
				users,
				intl,
				appContext,
				customerId,
				isCustomerCare,
				messages.resendInviteSuccess,
				messages.resendInviteSuccessPlural,
			),
	};

	return { resendInviteAction, resetPasswordAction };
};

export default useResetPasswordOrResendInviteAction;
