import React from 'react';
import { Checkbox, CheckboxProps, SIZE_SMALL } from '@planview/pv-uikit';
import messages from './NewUserDialog.messages';
import styled from 'styled-components';
import { text } from '@planview/pv-utilities';
import { Form, Input } from '@planview/pv-form';
import { useForm, UseFormReturnType } from '@mantine/form';
import isEmail from 'validator/lib/isEmail';
import validationMessages from '../../../helpers/validation.messages';
import { UserUpsertDto } from '../../../types/api/users';
import { IntlShape, useIntl } from 'react-intl';

const CheckboxLabel = styled.label`
	${text.regular};
	display: inline-flex;
	align-items: center;
`;

interface FormCheckboxProps extends CheckboxProps {
	checked?: boolean;
	name: string;
}

const FormCheckbox = (props: FormCheckboxProps) => (
	<Checkbox {...props} selected={props.checked} />
);

/**
 * The fields of a user on the UserDetailForm
 */
export type UserDetail = {
	firstName?: string | null;
	lastName?: string | null;
	email?: string | null;
	username?: string | null;
	loginAlias?: string | null;
	localAuthOverrideEnabled?: boolean | null;
};

export const useUserDetailsForm = (
	user: UserUpsertDto,
	intl: IntlShape,
	showUsername: boolean = false,
) => {
	return useForm({
		initialValues: {
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			username: user.username,
			loginAlias: user.loginAlias,
			localAuthOverrideEnabled: user.localAuthOverrideEnabled,
		},
		validateInputOnBlur: true,
		validate: {
			firstName: (value) =>
				!value
					? intl.formatMessage(validationMessages.enterFirstName)
					: null,
			lastName: (value) =>
				!value
					? intl.formatMessage(validationMessages.enterLastName)
					: null,
			email: (value) => {
				if (!value) {
					return intl.formatMessage(validationMessages.enterEmail);
				}
				if (!isEmail(value)) {
					return intl.formatMessage(validationMessages.invalidEmail);
				}
			},
			username: (value) =>
				showUsername && !value
					? intl.formatMessage(validationMessages.enterUsername)
					: null,
		},
	}) as UseFormReturnType<UserDetail, (values: UserDetail) => UserDetail>;
};

export type UserDetailsFormProps = {
	form: UseFormReturnType<UserDetail, (values: UserDetail) => UserDetail>;
	showLocalAuthOverride: boolean;
	showUsername?: boolean;
	showLoginAlias?: boolean;
};

export const UserDetailsForm = ({
	form,
	showLocalAuthOverride,
	showUsername = false,
	showLoginAlias = false,
}: UserDetailsFormProps) => {
	const intl = useIntl();
	return (
		<Form label="User Details">
			<Input
				label={intl.formatMessage(messages.firstName)}
				id="firstName"
				nativeProps={{
					name: 'firstName',
				}}
				{...form.getInputProps('firstName')}
				withAsterisk
			/>
			<Input
				label={intl.formatMessage(messages.lastName)}
				nativeProps={{
					name: 'lastName',
				}}
				{...form.getInputProps('lastName')}
				withAsterisk
			/>
			<Input
				label={intl.formatMessage(messages.email)}
				nativeProps={{
					name: 'email',
				}}
				type="email"
				{...form.getInputProps('email')}
				withAsterisk
			/>
			{showUsername ? (
				<Input
					label={intl.formatMessage(messages.username)}
					nativeProps={{
						name: 'username',
					}}
					{...form.getInputProps('username')}
					withAsterisk
				/>
			) : null}
			{showLoginAlias ? (
				<Input
					label={intl.formatMessage(messages.loginAlias)}
					nativeProps={{
						name: 'loginAlias',
					}}
					{...form.getInputProps('loginAlias')}
				/>
			) : null}
			{showLocalAuthOverride ? (
				<CheckboxLabel>
					<FormCheckbox
						data-testid="localAuthOverrideEnabled"
						id="localAuthOverrideEnabled"
						name="localAuthOverrideEnabled"
						size={SIZE_SMALL}
						{...form.getInputProps('localAuthOverrideEnabled', {
							type: 'checkbox',
						})}
					/>
					<label htmlFor="localAuthOverrideEnabled">
						{intl.formatMessage(messages.localAuthOverride)}
					</label>
				</CheckboxLabel>
			) : null}
		</Form>
	);
};
