import { Modal } from '../../../../components/common/modal/Modal';
import messages from './RemoveAvatarModal.messages';
import React from 'react';
import { useIntl } from 'react-intl';
import { DESTRUCTIVE, MODAL_SMALL } from '@planview/pv-uikit';

type RemoveAvatarModalProps = {
	onCancel: () => void;
	onConfirm: () => void;
};

const RemoveAvatarModal = ({ onCancel, onConfirm }: RemoveAvatarModalProps) => {
	const intl = useIntl();
	return (
		<Modal
			headerText={intl.formatMessage(messages.header)}
			onCancel={onCancel}
			onConfirm={onConfirm}
			confirmText={intl.formatMessage(messages.confirmButton)}
			cancelText={intl.formatMessage(messages.cancelButton)}
			size={MODAL_SMALL}
			type={DESTRUCTIVE}
		>
			{intl.formatMessage(messages.body)}
		</Modal>
	);
};

export default RemoveAvatarModal;
