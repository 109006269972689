import React, { useContext, useMemo, useState } from 'react';
import { AppContext, CustomerContext } from '../../../../context';
import { post } from '../../../../hooks/request/request';
import { getUserIds } from '../userUtils';
import { User } from '../../../../types';
import { ApiResponse } from '../../../../types/api/api';
import { ToastType } from '../../../../types/toast';
import { Modal, MODAL_MEDIUM } from '@planview/pv-uikit';
import { defineMessage, useIntl } from 'react-intl';
import { Form, Input } from '@planview/pv-form';
import { useForm } from '@mantine/form';
import { getValidationFunction } from '../../../../helpers/validation';
import { Action } from '../../../../types/menu';

const setPasswordMessage = defineMessage({
	id: 'customercare.users.setPassword',
	description: 'Action for customer care to set password for users',
	defaultMessage: 'Set password',
});

type SetPasswordDialogProps = {
	onConfirm: () => void;
	onCancel: () => void;
	customerId: string;
	users: User[];
};

/**
 * Dialog intended only for SCs to set passwords for users that are in SCDemo orgs, as well as
 * having planview.com email addresses.
 */
const SetPasswordDialog = (props: SetPasswordDialogProps) => {
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const { onConfirm, onCancel, users, customerId } = props;
	const [isSaving, setIsSaving] = useState(false);

	const validatePassword = useMemo<(value: string) => string | null>(
		() =>
			getValidationFunction(
				{
					type: 'password',
					required: true,
				},
				intl,
			),
		[intl],
	);
	const form = useForm({
		initialValues: {
			newPassword: '',
			confirmPassword: '',
		},
		validateInputOnBlur: true,
		validate: {
			newPassword: (val) => {
				return validatePassword(val) || null;
			},
			confirmPassword: (val, values) =>
				val !== values.newPassword ? 'Passwords do not match' : null,
		},
	});

	const handleSubmit = async () => {
		setIsSaving(true);

		const { newPassword } = form.values;
		const userIds = getUserIds(users);
		const dto = { userIds, customerId, newPassword };
		const { success, message } = (await post(
			`/io/v1/admin/user/password`,
			dto,
		)) as ApiResponse;

		appContext.showToast({
			type: success ? ToastType.SUCCESS : ToastType.DANGER,
			message,
		});

		if (success) {
			onConfirm();
		} else {
			setIsSaving(false);
		}
	};

	return (
		<Modal
			size={MODAL_MEDIUM}
			onConfirm={() => {
				setIsSaving(true);
				void handleSubmit();
			}}
			onCancel={onCancel}
			cancelText="Cancel"
			confirmText="Set password"
			headerText="Set password"
			disableConfirm={isSaving || !form.isValid()}
		>
			<Form label="Set password">
				<Input
					label="Password"
					type="password"
					{...form.getInputProps('newPassword')}
				/>
				<Input
					label="Confirm password"
					type="password"
					{...form.getInputProps('confirmPassword')}
				/>
			</Form>
		</Modal>
	);
};

/**
 * Enables SCs to set user passwords from the customer care portal / user grid for SC Demo org users.
 * The feature is only allowed on planview.com email addresses per
 * <a href="https://planview.leankit.com/card/2073232310">LK:2073232310</a>.
 */
const useSetPasswordAction = ({ refresh }: { refresh: () => void }) => {
	const customerContext = useContext(CustomerContext);
	const [showDialog, setShowDialog] = useState(false);
	const [users, setUsers] = useState<User[]>([]);

	const setPasswordModal = showDialog ? (
		<SetPasswordDialog
			key="setPasswordModal"
			customerId={customerContext.customer.id}
			users={users}
			onConfirm={() => {
				setShowDialog(false);
				refresh();
			}}
			onCancel={() => setShowDialog(false)}
		/>
	) : null;

	const setPasswordAction: Action<User> = {
		icon: null,
		message: setPasswordMessage,
		isEnabled: (users: User[]) =>
			users.length > 0 &&
			users.every((user) => user.email.endsWith('planview.com')),
		activateFn: (users: User[]) => {
			setUsers(users);
			setShowDialog(true);
		},
	};

	return {
		setPasswordModal,
		setPasswordAction,
	};
};

export default useSetPasswordAction;
