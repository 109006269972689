import React, { useContext, useEffect, useState } from 'react';
import messages from '../../actionsMenu/ActionsMenu.messages';
import { default as serverMessages } from '../../../../messages/server';
import { Application, EnvSelector, User } from '../../../../types';
import { AppContext } from '../../../../context';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { spacingPx, text } from '@planview/pv-utilities';
import {
	GetLicenseTypeResponseDto,
	LicenseDetail,
	LicenseType,
	UpdateLicenseTypeDto,
	UpdateLicenseTypeResponseDto,
} from '../../../../types/api/licenses';
import { ComboboxOption, Modal, MODAL_SMALL } from '@planview/pv-uikit';
import { get, post } from '../../../../hooks/request/request';
import { Combobox, Label } from '@planview/pv-form';
import { ToastType } from '../../../../types/toast';
import { Edit } from '@planview/pv-icons';
import { Action } from '../../../../types/menu';

const PromptContainer = styled.div`
	${text.regular};
	margin-bottom: calc(${spacingPx.small} + 6px);
`;

type EditLicenseTypeActionProps = {
	application: Application;
	refresh: () => void;
	setLicenseDetails: (licenseDetails: LicenseDetail[]) => void;
};

type EditLicenseTypeModalProps = {
	onConfirm: () => void;
	onCancel: () => void;
	refresh: () => void;
	setLicenseDetails: (licenseDetails: LicenseDetail[]) => void;
	userIds: string[];
	envSelector: EnvSelector;
	licenseTypes: LicenseType[];
};

const EditLicenseTypeModal = (props: EditLicenseTypeModalProps) => {
	const appContext = useContext(AppContext);
	const intl = useIntl();
	const {
		userIds,
		envSelector,
		onConfirm,
		onCancel,
		refresh,
		setLicenseDetails,
		licenseTypes,
	} = props;
	const [option, setOption] = useState<ComboboxOption | null>();
	const [licenseName, setLicenseName] = useState<string>();
	const [selectedLicenseName, setSelectedLicenseName] = useState<string>();

	useEffect(() => {
		const getLicenseType = async () => {
			const url = '/io/v1/user/license/type';
			const urlParams = {
				envSelector: envSelector.toString(),
				userId: userIds[0],
			};
			const queryString = new URLSearchParams(urlParams).toString();

			const { licenseName } = (await get(
				`${url}?${queryString}`,
			)) as GetLicenseTypeResponseDto;

			const licenseType = licenseTypes.find(
				(licenseType) => licenseType.name === licenseName,
			);

			if (licenseType) {
				const { displayText, name } = licenseType;
				setLicenseName(name);
				setSelectedLicenseName(name);

				const defaultOption = licenseType
					? {
							label: displayText,
							value: name,
						}
					: null;

				setOption(defaultOption);
			}
		};

		if (userIds.length === 1) {
			void getLicenseType();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sendEditLicenseTypeRequest = async (
		userIds: string[],
		envSelector: EnvSelector,
		licenseName: string,
	) => {
		const url = '/io/v1/user/license/type';
		const dto: UpdateLicenseTypeDto = {
			userIds,
			envSelector,
			licenseName,
		};

		const { success, message, licenseDetails } = (await post(
			url,
			dto,
		)) as UpdateLicenseTypeResponseDto;
		appContext.showToast({
			message:
				message || intl.formatMessage(serverMessages.unexpectedError),
			type: success ? ToastType.SUCCESS : ToastType.DANGER,
		});

		if (success) {
			setLicenseDetails(licenseDetails);
		}
	};

	const options = licenseTypes.map((licenseType): ComboboxOption => {
		const { displayText, name, unlimited } = licenseType;
		const label = unlimited ? displayText : displayText;
		return {
			label,
			value: name,
		};
	});

	const prompt =
		userIds.length > 1
			? messages.assignPromptPlural
			: messages.assignPrompt;

	return (
		<Modal
			headerText={intl.formatMessage(messages.editLicenseType)}
			confirmText={intl.formatMessage(messages.save)}
			cancelText={intl.formatMessage(messages.cancel)}
			size={MODAL_SMALL}
			onConfirm={async () => {
				if (selectedLicenseName) {
					await sendEditLicenseTypeRequest(
						userIds,
						envSelector,
						selectedLicenseName,
					);
					onConfirm();
					refresh();
				}
			}}
			onCancel={onCancel}
			disableConfirm={licenseName === selectedLicenseName}
		>
			<PromptContainer>
				<FormattedMessage {...prompt} />
			</PromptContainer>
			<Label>
				<FormattedMessage {...messages.licenseType} />
			</Label>
			<Combobox
				value={option}
				onChange={(nextState) => {
					setOption(nextState);
					setSelectedLicenseName(nextState?.value as string);
				}}
				options={options}
				clearable={false}
			/>
		</Modal>
	);
};

const useEditLicenseTypeAction = ({
	application,
	refresh,
	setLicenseDetails,
}: EditLicenseTypeActionProps) => {
	const { envSelector, licenseTypes } = application;
	const [showEditLicenseType, setShowEditLicenseType] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

	const editLicenseTypeAction: Action<User> = {
		message: messages.editLicenseType,
		icon: <Edit />,
		activateFn: (users: User[]) => {
			setSelectedUsers(users);
			setShowEditLicenseType(true);
		},
		isEnabled: (users: User[]) => {
			return users.length > 0;
		},
	};

	const userIds = selectedUsers.map(({ id }) => id);

	const editLicenseTypeModal = showEditLicenseType ? (
		<EditLicenseTypeModal
			key="editLicenseTypeModal"
			userIds={userIds}
			setLicenseDetails={setLicenseDetails}
			envSelector={envSelector}
			licenseTypes={licenseTypes}
			onConfirm={() => setShowEditLicenseType(false)}
			onCancel={() => setShowEditLicenseType(false)}
			refresh={refresh}
		/>
	) : null;

	return { editLicenseTypeAction, editLicenseTypeModal };
};

export default useEditLicenseTypeAction;
