import React, { useContext } from 'react';
import { ListItem } from '@planview/pv-uikit';
import { AppContext, AppContextProps } from '../../../context';
import { downloadUrl } from '../../../hooks/request/request';
import useFindUserAction from './hooks/useFindUserAction';
import { ToolbarDropdownMenu } from '@planview/pv-toolbar';
import { ToastType } from '../../../types/toast';
import useFindOrgAction from './hooks/useFindOrgAction';

const downloadCustomers = ({ appContext }: { appContext: AppContextProps }) => {
	appContext.showToast({
		message:
			'Export of customers has started. The file will download once complete.',
		type: ToastType.INFO,
	});

	const dateStr = new Date().toISOString().split('T')[0];
	const fileName = `customers_${dateStr}.csv`;

	void downloadUrl({
		url: `/io/v1/admin/customer/csv`,
		fileName,
	});
};

const ToolbarActionsMenu = () => {
	const appContext = useContext(AppContext);
	const { findUserAction, modals } = useFindUserAction();
	const { findOrgAction, modals: findOrgModals } = useFindOrgAction();

	const trigger = {
		label: 'Actions',
		withCaret: true,
	};

	return (
		<>
			<ToolbarDropdownMenu alignRight={true} trigger={trigger} label="">
				<ListItem
					key="find-user"
					label="Find user"
					{...findUserAction}
				/>
				<ListItem
					key="find-org"
					label="Find organization"
					{...findOrgAction}
				/>
				<ListItem
					key="export-customers"
					label="Export customer list"
					onActivate={() => downloadCustomers({ appContext })}
				/>
			</ToolbarDropdownMenu>
			{modals}
			{findOrgModals}
		</>
	);
};

export default ToolbarActionsMenu;
