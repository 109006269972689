// very basic regex something that looks like a UUID.
// This doesn't check length, just valid characters
import { ComboboxOption } from '@planview/pv-uikit';
import {
	Application,
	ApplicationName,
	Customer,
	EnvSelector,
	FeatureFlag,
	Tenant,
} from '../types';
import { UserTenantAssignment } from '../types/api/users';
import { OrgTag } from '../types/api/orgTags';
import { AppContextProps } from '../context';
import { TenantsByEnvSelector } from '../types/api/tenants';
import { ApplicationsById } from '../context/appContext';

const UUIDRegex = /^[0-9a-fA-F-]+$/;

// exported only for unit tests
export const hasUniqueSamlEntityId = (samlEntityId: string) => {
	const index = samlEntityId.lastIndexOf('/');
	if (index > 0) {
		const id = samlEntityId.substring(index + 1);
		return UUIDRegex.test(id);
	}
	return false;
};

export const getRandomNumber = () =>
	Math.floor(Math.random() * Math.pow(10, 9));

export const isLicensingSupported = (
	application: ApplicationName,
	{ enableIdeaplaceLicensing }: FeatureFlag,
) =>
	application === 'ROADMAPS' ||
	isIdeaPlaceLicensingSupported(application, !!enableIdeaplaceLicensing);

export const isIdeaPlaceLicensingSupported = (
	application: ApplicationName,
	enableIdeaplaceLicensing: boolean,
) => {
	return application === 'TEAMTAP' && enableIdeaplaceLicensing;
};

export const isAppDeletable = (
	{ enableAllTenantsDeletable }: FeatureFlag,
	{ deletable }: Application,
) => enableAllTenantsDeletable || deletable;

export const isMissingRoles = (
	userTenantAssignments: UserTenantAssignment[],
) => {
	return userTenantAssignments
		.filter(({ roleRequired }) => roleRequired)
		.some(({ role }) => !role);
};

export const createOrgTagOption = ({ id, label }: OrgTag): ComboboxOption => {
	return {
		value: id,
		label,
	};
};

export const getOrgTagsByName = (orgTags: OrgTag[]) => {
	return new Map(orgTags.map((orgTag) => [orgTag.id, orgTag]));
};

export const isDemoCustomer = (customer: Customer) =>
	!!customer.orgTags.find((orgTag) => orgTag.includes('DEMO'));

export const getApplicationsById = (applications: Application[]) => {
	return Object.fromEntries(
		applications.map((application) => {
			return [application.envSelectorString, application];
		}),
	) as ApplicationsById;
};

export const getTenantsByEnvSelector = (tenants: Tenant[]) => {
	return Object.fromEntries(
		tenants.map((tenant) => [
			EnvSelector.fromTenant(tenant).toString(),
			tenant,
		]),
	) as TenantsByEnvSelector;
};

/**
 * Update applications in the appContext
 * @param appContext the current AppContext
 * @param applicationToUpdate the Application to update in the AppContext
 */
export const updateApplications = (
	{ applications, addApplications }: AppContextProps,
	applicationToUpdate: Application,
) => {
	const updatedApplications = applications.map((application) =>
		application.envSelectorEncodedString ===
		applicationToUpdate.envSelectorEncodedString
			? applicationToUpdate
			: application,
	);

	addApplications(updatedApplications);
};

// 2 to 63 characters, lowercase letters, numbers, hyphens
// must start with letter, end with letter or number
// See also SubdomainManager.java
const DOMAIN_REGEX = /^[a-z][a-z0-9-]{0,61}[a-z0-9]$/;

export const isDomainValid = (domain: string) => {
	return DOMAIN_REGEX.test(domain);
};
