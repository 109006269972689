import { defineMessages } from 'react-intl';

const messages = defineMessages({
	firstNameColumn: {
		id: 'admin.mapTo.column.firstName',
		description: 'Label for First Name column for Map To User Grid',
		defaultMessage: 'First Name',
	},
	lastNameColumn: {
		id: 'admin.mapTo.column.lastName',
		description: 'Label for Last Name column for Map To User Grid',
		defaultMessage: 'Last Name',
	},
	emailColumn: {
		id: 'admin.mapTo.column.email',
		description: 'Label for Email column for Map To User Grid',
		defaultMessage: 'Email',
	},
	usernameColumn: {
		id: 'admin.mapTo.column.username',
		description: 'Label for Username column for Map To User Grid',
		defaultMessage: 'Username',
	},
	header: {
		id: 'admin.mapTo.dialog.header',
		description: 'Header for Map Users dialog',
		defaultMessage: 'Map User',
	},
	mapButton: {
		id: 'admin.mapTo.dialog.button.map',
		description: 'Button text for Map',
		defaultMessage: 'Map',
	},
	newPlanviewAccount: {
		id: 'admin.mapTo.dialog.newAccount',
		description: 'Radio input label',
		defaultMessage: 'New Planview account',
	},
	existingPlanviewAccount: {
		id: 'admin.mapTo.dialog.existingAccount',
		description: 'Label to select an existing account from the grid',
		defaultMessage: 'Or existing Planview account',
	},
	mapText: {
		id: 'admin.mapTo.dialog.text.map',
		description: 'Label to show the user that is being mapped',
		defaultMessage: 'Map:',
	},
	toText: {
		id: 'admin.mapTo.dialog.text.to',
		description: 'Label to select the user that is being mapped to',
		defaultMessage: 'to:',
	},
	noMappingOption: {
		id: 'admin.mapTo.dialog.map.noSelection',
		description: 'Error message when there is no mapping option selected',
		defaultMessage: 'Please select a mapping option',
	},
});

export default messages;
