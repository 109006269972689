import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedDate, injectIntl, useIntl } from 'react-intl';
import styled from 'styled-components';
import Grid from '../../../components/common/grid/Grid';
import messages from '../mappedUsers/MappedUsersGrid.messages';
import actionMessages from '../actionsMenu/ActionsMenu.messages';
import filterMessages from '../../../components/common/filter/Filter.messages';
import { Filter } from '@planview/pv-icons';
import {
	Toolbar,
	ToolbarButtonEmpty,
	ToolbarSection,
	ToolbarSeparator,
} from '@planview/pv-toolbar';
import {
	GridCellDefault,
	GridEditorCombobox,
	useLocalStoragePreferences,
} from '@planview/pv-grid';
import {
	FilterContainer,
	ListWrapper,
	MainContainer,
} from '../../../components/common/Wrappers';
import {
	FilterBanner,
	FilterPanel,
	FilterSectionList,
	FilterSectionRangeDate,
	FilterSectionText,
} from '@planview/pv-filter';
import { theme } from '@planview/pv-utilities';
import { useDebounce } from '../../../hooks/useDebounce';
import useProductActions from './hooks/useProductActions';
import GridActionsMenu from '../actionsMenu/GridActionsMenu';
import ToolbarActionsMenu from '../actionsMenu/ToolbarActionsMenu';
import { AppContext } from '../../../context';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import { isLicensingSupported } from '../../../helpers/util';
import { beginningOfTime, endOfDays } from '../users/UsersFilter';
import { Tooltip } from '@planview/pv-uikit';

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	background-color: ${theme.backgroundNeutral0};
`;

const RoleDiv = styled.div`
	height: 100%;
`;

const dateCellRenderer = ({ value }) =>
	value ? <FormattedDate value={new Date(value)} /> : '';

const LicenseFilter = ({ appDetails, filterParams, onChange }) => {
	const intl = useIntl();
	const licenseTypes = appDetails.licenseTypes;
	const appContext = useContext(AppContext);
	const { featureFlags } = appContext;
	const options = React.useMemo(() => {
		const licenseFilterOptions = [];
		for (let licenseType in licenseTypes) {
			licenseFilterOptions.push({
				id: licenseTypes[licenseType].name,
				label: licenseTypes[licenseType].displayText,
			});
		}
		return licenseFilterOptions;
	}, [licenseTypes]);

	return isLicensingSupported(appDetails.app, featureFlags) &&
		licenseTypes?.length ? (
		<FilterSectionList
			label={intl.formatMessage(messages.licenseTypeColumn)}
			value={filterParams.licenseName}
			options={options}
			onChange={(licenseName) => onChange('licenseName', licenseName)}
		/>
	) : null;
};

const RoleFilter = ({ appDetails, filterParams, onChange }) => {
	const intl = useIntl();
	const roles = appDetails.roles;

	const options = React.useMemo(() => {
		const roleFilterOptions = [];
		for (let role in roles) {
			roleFilterOptions.push({
				id: roles[role].id,
				label: roles[role].displayText,
			});
		}
		return roleFilterOptions;
	}, [roles]);

	return roles?.length ? (
		<FilterSectionList
			label={intl.formatMessage(messages.roleColumn)}
			value={filterParams.role}
			options={options}
			onChange={(role) => onChange('role', role)}
		/>
	) : null;
};

const LastLoginFilter = ({ filterParams, onChange }) => {
	const intl = useIntl();

	return (
		<FilterSectionRangeDate
			label={intl.formatMessage(messages.lastLoginAtColumn)}
			value={parseDates(filterParams.lastLoginAt)}
			onChange={(value) => {
				let startDate = value[0];
				let endDate = value[1];

				if (startDate === null && endDate === null) {
					// clear filter case:
					filterParams.lastLoginAt = [];
					onChange('lastLoginAt', new Set([]));
				} else {
					// if we have a null startDate, make it the beginning to time:
					if (startDate === null) {
						startDate = new Date(beginningOfTime);
					}

					// if we have a null endDate, make it the end of days:
					if (endDate === null) {
						endDate = new Date(endOfDays);
					}

					// if not previously set, do so now:
					if (!filterParams.lastLoginAt) {
						filterParams.lastLoginAt = [];
					}

					// set start date in params:
					filterParams.lastLoginAt[0] = startDate;

					// set end date in params:
					filterParams.lastLoginAt[1] = endDate;

					// convert Dates to ISO strings, and notify onChange:
					onChange(
						'lastLoginAt',
						new Set([
							filterParams.lastLoginAt[0].toISOString(),
							filterParams.lastLoginAt[1].toISOString(),
						]),
					);
				}
			}}
		/>
	);
};

/**
 * Parses the dates already set for filtering for use by FilterSectionRangeDate.
 * @param filterDates the user-set dates for filtering
 * @return the array of Dates
 */
const parseDates = (filterDates) => {
	if (filterDates === undefined) {
		return [];
	}
	let startDateString = filterDates[0];
	if (startDateString === beginningOfTime) {
		startDateString = null;
	}

	let endDateString = filterDates[1];
	if (endDateString === endOfDays) {
		endDateString = null;
	}

	return [
		startDateString === null ? null : new Date(startDateString),
		endDateString === null ? null : new Date(endDateString),
	];
};

const handleSetRole = async ({
	intl,
	app,
	appContext,
	userIds,
	refresh,
	roleId,
}) => {
	const rolesById = app.roles.reduce(
		(acc, role) => acc.set(role.id, role),
		new Map(),
	);
	const role = rolesById.get(roleId);

	const dataObj = userIds.map((userId) => ({
		userId,
		envSelector: app.envSelectorString,
		role: role.id,
	}));

	const message = intl.formatMessage(actionMessages.setRoleSuccess, {
		role: role.displayText,
	});

	await requestWithErrorHandling({
		method: 'post',
		url: `/io/v1/user/role`,
		dataObj,
		appContext,
		intl,
		successMessage: message,
	});

	refresh();
};

const handleSetLicenseType = async ({
	intl,
	app,
	appContext,
	userIds,
	refresh,
	licenseName,
	setLicenseDetails,
}) => {
	const licenseType = app.licenseTypes.find(
		(license) => license.name === licenseName,
	);

	const dataObj = {
		userIds,
		envSelector: app.envSelectorEncodedString,
		licenseName: licenseType.name,
	};

	const message = intl.formatMessage(actionMessages.setLicenseTypeSuccess, {
		licenseType: licenseType.displayText,
	});

	const { licenseDetails } = await requestWithErrorHandling({
		method: 'post',
		url: '/io/v1/user/license/type',
		dataObj,
		appContext,
		intl,
		successMessage: message,
	});

	if (licenseDetails) {
		setLicenseDetails(licenseDetails);
	}

	refresh();
};

const ProductUsersGrid = ({ ...props }) => {
	const { appDetails, intl, setLicenseDetails } = props;
	const application = appDetails.app;
	const grid = useRef();
	const refresh = () => grid.current.refresh();
	const [filterVisible, setFilterVisible] = useState(false);
	const [filterParams, setFilterParams] = useState({});
	const [selectedItems, setSelectedItems] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [lastSearchValue, setLastSearchValue] = useState('');
	const appContext = useContext(AppContext);
	const { featureFlags } = appContext;
	const getUsersGridColumns = (intl, app) => {
		const { formatMessage } = intl;
		const { roles, licenseTypes, shouldShowAddedOnColumn } = app;
		const columns = [
			{
				id: 'firstName',
				label: formatMessage(messages.firstNameColumn),
				width: 200,
			},
			{
				id: 'lastName',
				label: formatMessage(messages.lastNameColumn),
				width: 200,
			},
			{
				id: 'email',
				label: formatMessage(messages.emailColumn),
				width: 300,
			},
		];

		if (featureFlags.enableUsernames) {
			columns.push({
				id: 'username',
				label: formatMessage(messages.usernameColumn),
				width: 300,
			});
		}

		if (shouldShowAddedOnColumn) {
			columns.push({
				id: 'addedOn',
				label: formatMessage(messages.addedOnColumn),
				cell: {
					label: (data) => dateCellRenderer(data),
				},
				width: 150,
			});
		}

		if (app.ssoEnabled || app.foundationApp) {
			columns.push({
				id: 'lastLoginAt',
				label: intl.formatMessage(messages.lastLoginAtColumn),
				sortable: true,
				width: 80,
				cell: {
					label: (data) => dateCellRenderer(data),
				},
			});
		}

		if (
			isLicensingSupported(application, featureFlags) &&
			licenseTypes?.length
		) {
			const licenseTypesByName = licenseTypes.reduce(
				(acc, licenseType) => acc.set(licenseType.name, licenseType),
				new Map(),
			);

			const licenseTypeOptions = licenseTypes.map((licenseType) => {
				const { displayText, name } = licenseType;
				return {
					label: displayText,
					value: name,
				};
			});

			columns.push({
				id: 'licenseName',
				label: formatMessage(messages.licenseTypeColumn),
				sortable: false,
				width: 200,
				cell: {
					editable: true,
					Renderer(props) {
						return <GridCellDefault {...props} withCaret />;
					},
					label: ({ value }) => {
						let string = '';
						if (value) {
							const licenseType = licenseTypesByName.get(value);
							if (licenseType) {
								string = licenseType.displayText;
							}
						}
						return string;
					},
					Editor(props) {
						return (
							<GridEditorCombobox
								{...props}
								clearable={false}
								options={licenseTypeOptions}
								value={licenseTypeOptions.find(
									(option) => option.value === props.value,
								)}
								onConfirm={(value, payload) => {
									props.onConfirm(value.value, payload);
								}}
							/>
						);
					},
				},
			});
		}

		if (roles && roles.length > 0) {
			const rolesById = app.roles.reduce(
				(acc, role) => acc.set(role.id, role),
				new Map(),
			);

			const filteredRoles = roles.filter((role) => !role.readOnly);
			const roleOptions = filteredRoles.map((role) => {
				const { displayText, id } = role;
				return {
					label: displayText,
					value: id,
				};
			});

			columns.push({
				id: 'role',
				label: formatMessage(messages.roleColumn),
				sortable: false,
				width: 200,
				cell: {
					editable: ({ row }) => {
						const role = rolesById.get(row.role);
						return role?.readOnly ? false : true;
					},
					Renderer(props) {
						const role = rolesById.get(props.value);
						return (
							<Tooltip
								unwrapped
								text={
									role?.readOnly
										? intl.formatMessage(
												messages.readOnlyMessage,
												{ roleName: role.displayText },
											)
										: null
								}
							>
								<RoleDiv>
									<GridCellDefault
										{...props}
										withCaret={
											role?.readOnly ? false : true
										}
									/>
								</RoleDiv>
							</Tooltip>
						);
					},
					label: ({ value }) => {
						let string = '';
						if (value) {
							const role = rolesById.get(value);
							if (role) {
								string = role.displayText;
							}
						}
						return string;
					},
					Editor(props) {
						return (
							<GridEditorCombobox
								{...props}
								clearable={false}
								options={roleOptions}
								value={roleOptions.find(
									(option) => option.value === props.value,
								)}
								onConfirm={(value, payload) => {
									props.onConfirm(value.value, payload);
								}}
							/>
						);
					},
				},
			});
		}

		return columns;
	};

	const columns = getUsersGridColumns(intl, appDetails);
	const preferencesKey = columns.map((c) => c.id).join('-');
	const preferencesAdapter = useLocalStoragePreferences(preferencesKey);

	const { menuActions, toolbarActions, modals } = useProductActions(
		refresh,
		appDetails,
		setLicenseDetails,
	);

	const filterTooltip = intl.formatMessage(
		filterMessages.filterButtonTooltip,
	);

	const activeFilters =
		Object.keys(filterParams).length > 0 || !!lastSearchValue;

	const debouncedSeachValue = useDebounce(searchValue, 750);
	useEffect(
		() => {
			if (lastSearchValue !== debouncedSeachValue) {
				setLastSearchValue(debouncedSeachValue);
				grid.current.triggerSearch(debouncedSeachValue);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debouncedSeachValue],
	);

	const handleCloseFilterClick = () => {
		setFilterVisible(false);
	};

	const handleClearAllClick = () => {
		setFilterParams({});
		setSearchValue('');
		setLastSearchValue('');
		grid.current.resetFiltering({});
	};

	const handleFilterChange = (key, value) => {
		const newParams = { ...filterParams };
		const valueArr = value.size ? [...value] : null;

		if (
			valueArr === null ||
			(Array.isArray(valueArr) && valueArr.length === 0)
		) {
			delete newParams[key];
		} else {
			newParams[key] = valueArr;
		}
		setFilterParams(newParams);
		grid.current.setFilterParams(newParams);
	};

	const RowActionsMenu = (props) => (
		<GridActionsMenu hideDisabled={false} {...props} />
	);

	return (
		<React.Fragment>
			<Toolbar data-testid="toolbar">
				<ToolbarSection>
					<ToolbarButtonEmpty
						data-testid="toggle-filters"
						aria-label={filterTooltip}
						icon={<Filter />}
						activated={filterVisible}
						onClick={() => setFilterVisible(!filterVisible)}
						tooltip={filterTooltip}
					/>
					<ToolbarSeparator />
				</ToolbarSection>
				<ToolbarSection>
					<ToolbarActionsMenu
						rowData={selectedItems}
						actions={toolbarActions}
						message={messages.bulkActionsButton}
						alignRight={true}
						hideDisabled={false}
					/>
				</ToolbarSection>
			</Toolbar>
			<Container>
				<FilterContainer
					aria-hidden={!filterVisible}
					aria-label="Filter Panel"
					$filterVisible={filterVisible}
				>
					{filterVisible && (
						<FilterPanel
							onClear={handleClearAllClick}
							onClose={handleCloseFilterClick}
							clearEnabled={activeFilters}
						>
							<FilterSectionText
								label={intl.formatMessage(messages.userSearch)}
								value={searchValue}
								onChange={setSearchValue}
								placeholder={intl.formatMessage(
									filterMessages.typeToSearch,
								)}
							/>
							<LicenseFilter
								appDetails={appDetails}
								filterParams={filterParams}
								onChange={handleFilterChange}
							/>
							<RoleFilter
								appDetails={appDetails}
								filterParams={filterParams}
								onChange={handleFilterChange}
							/>
							<LastLoginFilter
								filterParams={filterParams}
								onChange={handleFilterChange}
							/>
						</FilterPanel>
					)}
				</FilterContainer>
				<MainContainer $filterVisible={filterVisible}>
					{activeFilters ? (
						<FilterBanner
							matching={0}
							total={0}
							onClear={handleClearAllClick}
						/>
					) : null}
					<ListWrapper $activeFilters={activeFilters}>
						<Grid
							columns={columns}
							actionsMenu={RowActionsMenu}
							onCellChange={(payload) => {
								if (payload.columnId === 'role') {
									handleSetRole({
										intl,
										app: appDetails,
										appContext,
										userIds: [payload.rowId],
										refresh,
										roleId: payload.nextValue,
									});
								} else if (payload.columnId === 'licenseName') {
									handleSetLicenseType({
										intl,
										app: appDetails,
										appContext,
										userIds: [payload.rowId],
										refresh,
										licenseName: payload.nextValue,
										setLicenseDetails,
									});
								}
							}}
							actions={menuActions}
							showActionsColumn={menuActions.length > 0}
							selectionType="checkbox"
							onSelectionChange={(items) =>
								setSelectedItems(items)
							}
							url={`/io/v1/user/mapped/${appDetails.envSelectorEncodedString}`}
							innerRef={(ref) => {
								grid.current = ref;
							}}
							clearFilters={handleClearAllClick}
							preferencesAdapter={preferencesAdapter}
							{...props}
						/>
					</ListWrapper>
				</MainContainer>
			</Container>
			{modals}
		</React.Fragment>
	);
};

export default injectIntl(ProductUsersGrid);
