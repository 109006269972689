import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import {
	Modal as PVUIKitModal,
	MODAL_SMALL,
	ModalProps as PVUIKitModalProps,
} from '@planview/pv-uikit';
import messages from './Modal.messages';
import { text } from '@planview/pv-utilities';

const StyledModal = styled(PVUIKitModal)`
	${text.regular};
`;

export const Body = styled.div`
	line-height: 1.5em;
	margin-bottom: 30px;
`;

// 'confirmText' required by PVUIKitModalProps but optional in our Modal
type BaseModalProps = Omit<PVUIKitModalProps, 'confirmText'>;
interface ModalProps extends BaseModalProps {
	confirmText?: string;
}

export const Modal = (props: ModalProps) => {
	const intl = useIntl();

	const { confirmText, cancelText, ...rest } = props;

	return (
		<StyledModal
			{...(rest as PVUIKitModalProps)}
			confirmText={confirmText ?? intl.formatMessage(messages.save)}
			cancelText={cancelText ?? intl.formatMessage(messages.cancel)}
		/>
	);
};

interface ConfirmationModalProps extends ModalProps {
	message: string | MessageDescriptor;
	size?: number;
}

export const ConfirmationModal = (
	// 'children' required by ModalProps but not used by ConfirmationModal
	props: Omit<ConfirmationModalProps, 'children'>,
) => {
	const { message, size = MODAL_SMALL, ...rest } = props;

	return (
		<Modal size={size} {...rest}>
			<Body data-testid="modal-message">
				{typeof message === 'string' ? (
					message
				) : (
					<FormattedMessage {...message} />
				)}
			</Body>
			<FormattedMessage {...messages.continueText} />
		</Modal>
	);
};

export default { Modal, ConfirmationModal };
