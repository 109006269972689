import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { theme, spacingPx } from '@planview/pv-utilities';
import SSOSettings from './SSOSettings';
import ServiceSettings from './ServiceSettings';
import { HorizontalTab, HorizontalTabGroup } from '@planview/pv-uikit';
import messages from './SettingsPage.messages';
import { useNavigate, useParams } from 'react-router-dom';
import ClientCredentialsList from './ClientCredentials/ClientCredentialsList';
import NotificationsSettings from './NotificationsSettings';
import OrganizationProfileSettings from './OrganizationProfile/OrganizationProfileSettings';
import { AppContext } from '../../../context';

export const settingPageContainerWidth = '1200px';

const Container = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;

const StyledTabGroup = styled(HorizontalTabGroup)`
	background-color: ${theme.backgroundNeutral0};
	padding-left: ${spacingPx.small};
`;

const TabBody = styled.div`
	flex-grow: 1;
	min-height: 0;
	padding-top: ${spacingPx.xsmall};
	display: flex;
	flex-direction: column;
`;

const WhiteContainer = styled.div`
	height: 100%;
	overflow: auto;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: ${spacingPx.large};
	margin-top: ${spacingPx.medium};
	background-color: ${theme.backgroundNeutral0};
	width: ${settingPageContainerWidth};
	max-width: 100%;
`;

export enum SettingsPageTabs {
	SERVICES,
	CLIENT_CREDENTIALS,
	NOTIFICATIONS,
	SSO,
	ORGANIZATION_PROFILE,
}

const tabMap: { [key: string]: SettingsPageTabs } = {
	services: SettingsPageTabs.SERVICES,
	clientcredentials: SettingsPageTabs.CLIENT_CREDENTIALS,
	notifications: SettingsPageTabs.NOTIFICATIONS,
	sso: SettingsPageTabs.SSO,
	orgprofile: SettingsPageTabs.ORGANIZATION_PROFILE,
};

type SettingsPageParams = {
	tab: string;
};

const SettingsPage = () => {
	const { tab = '' } = useParams<SettingsPageParams>();
	const activeTab = tabMap[tab];
	const intl = useIntl();
	const navigate = useNavigate();
	const { featureFlags } = useContext(AppContext);

	const tabs = [
		<HorizontalTab
			label={intl.formatMessage(messages.tabServicesSettings)}
			onActivate={() => {
				navigate(`/admin/settings/services`, {
					state: { redirect: true },
				});
			}}
			key="services"
		/>,
	];

	tabs.push(
		<HorizontalTab
			label={intl.formatMessage(messages.tabClientCredentialsSettings)}
			onActivate={() => {
				navigate(`/admin/settings/clientcredentials`, {
					state: { redirect: true },
				});
			}}
			key="clientcredentials"
		/>,
	);

	tabs.push(
		<HorizontalTab
			label={intl.formatMessage(messages.tabNotificationSettings)}
			onActivate={() => {
				navigate(`/admin/settings/notifications`, {
					state: { redirect: true },
				});
			}}
			key="notifications"
		/>,
	);

	tabs.push(
		<HorizontalTab
			label={intl.formatMessage(messages.tabSSOSettings)}
			onActivate={() => {
				navigate(`/admin/settings/sso`, {
					state: { redirect: true },
				});
			}}
			key="sso"
		/>,
	);

	if (featureFlags.enableImageSupport) {
		tabs.push(
			<HorizontalTab
				label="Organization Profile"
				onActivate={() => {
					navigate(`/admin/settings/orgprofile`, {
						state: { redirect: true },
					});
				}}
				key="orgprofile"
			/>,
		);
	}

	return (
		<Container>
			<StyledTabGroup defaultIndex={0} index={activeTab}>
				{tabs}
			</StyledTabGroup>
			{activeTab === SettingsPageTabs.SERVICES ? (
				<TabBody>
					<WhiteContainer>
						<ServiceSettings />
					</WhiteContainer>
				</TabBody>
			) : null}
			{activeTab === SettingsPageTabs.CLIENT_CREDENTIALS ? (
				<TabBody>
					<ClientCredentialsList />
				</TabBody>
			) : null}
			{activeTab === SettingsPageTabs.NOTIFICATIONS ? (
				<TabBody>
					<WhiteContainer>
						<NotificationsSettings />
					</WhiteContainer>
				</TabBody>
			) : null}
			{activeTab === SettingsPageTabs.SSO ? (
				<TabBody>
					<WhiteContainer>
						<SSOSettings />
					</WhiteContainer>
				</TabBody>
			) : null}
			{activeTab === SettingsPageTabs.ORGANIZATION_PROFILE ? (
				<TabBody>
					<WhiteContainer>
						<OrganizationProfileSettings />
					</WhiteContainer>
				</TabBody>
			) : null}
		</Container>
	);
};

export default SettingsPage;
