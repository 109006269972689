import React from 'react';
import { Modal, MODAL_SMALL } from '@planview/pv-uikit';
import messages from './AddToBusinessUnitModal.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { spacingPx, text } from '@planview/pv-utilities';

type AddToBusinessUnitModalProps = {
	headerText: string;
	confirmText: string;
	cancelText?: string;
	onConfirm: () => void;
	onCancel: () => void;
	description: string;
};

const MessageContainer = styled.div`
	${text.regular};
	margin-bottom: ${spacingPx.small};
`;

const AddToBusinessUnitModal = (props: AddToBusinessUnitModalProps) => {
	const {
		headerText,
		confirmText,
		cancelText,
		onConfirm,
		onCancel,
		description,
	} = props;
	const intl = useIntl();
	return (
		<Modal
			id={'add-to-business-unit-modal'}
			size={MODAL_SMALL}
			headerText={headerText}
			confirmText={confirmText}
			cancelText={cancelText || intl.formatMessage(messages.cancel)}
			onConfirm={onConfirm}
			onCancel={onCancel}
		>
			<MessageContainer>{description}</MessageContainer>
			<MessageContainer>
				<FormattedMessage {...messages.confirmationPrompt} />
			</MessageContainer>
		</Modal>
	);
};

export default AddToBusinessUnitModal;
